import { createUploadLink } from 'apollo-upload-client';
import { ApolloClient, from, InMemoryCache } from '@apollo/client/core';
import { AuthenticationJwtTokenRetriever } from '@/common/domain/auth/AuthenticationJwtTokenRetriever';
import { setContext } from '@apollo/client/link/context';
import { GraphQLRequest } from '@apollo/client/link/core';

const addTokenBearerTo = (headers: any) => (token: string) => ({
  headers: {
    ...headers,
    authorization: `Bearer ${token}`,
  },
});

export const addJwt =
  (jwtRetriever: AuthenticationJwtTokenRetriever) =>
  (operation: GraphQLRequest, { headers }: any) =>
    jwtRetriever.jwtToken().then(jwtToken => jwtToken.map(addTokenBearerTo(headers)).orElse({ headers }));

export const createApolloClient = (jwtRetriever: AuthenticationJwtTokenRetriever): ApolloClient<any> => {
  const httpLink = createUploadLink({
    uri: `${import.meta.env.VITE_KRIPTOWN_BACKEND_URL}/graphql`,
    headers: { 'Apollo-Require-Preflight': 'true', Provider: 'FAIRPLAYER' },
  });

  const authMiddleware = setContext(addJwt(jwtRetriever));

  return new ApolloClient({
    link: from([authMiddleware, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
