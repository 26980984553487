import { InjectionKey } from 'vue';
import { KycQuestion } from '@/fairplayer/domain/kyc/KycQuestion';
import { KycAnsweredQuestion } from '@/fairplayer/domain/kyc/KycAnsweredQuestion';

export const kycRepositoryKey: InjectionKey<KycRepository> = Symbol();

export interface KycRepository {
  listKycQuestions(): Promise<KycQuestion[]>;
  send(kycAnsweredQuestions: KycAnsweredQuestion[]): Promise<void>;
}
