import { ModalBus } from '@/common/domain/modal/ModalBus';
import { Emitter, Handler } from 'mitt';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { ModalOpened } from '@/common/domain/modal/ModalOpened';
import { BusCallback } from '@/common/domain/BusCallback';

const OPEN = 'open-modal';
const CLOSE = 'close-modal';

export class MittModalBus implements ModalBus {
  constructor(private emitter: Emitter<any>) {}

  open(modal: ModalOpened): void {
    this.emitter.emit(OPEN, modal);
  }

  close(): void {
    this.emitter.emit(CLOSE);
  }

  opened(callback: BusCallback<ModalOpened>): Unsubscribe {
    const handler: Handler<ModalOpened> = modal => callback(modal);
    this.emitter.on(OPEN, handler);
    return () => this.emitter.off(OPEN, handler);
  }

  closed(callback: BusCallback<void>): Unsubscribe {
    const handler: Handler = () => callback();
    this.emitter.on(CLOSE, handler);
    return () => this.emitter.off(CLOSE, handler);
  }

  emit(event: string): void {
    this.emitter.emit(event);
  }

  onEmitted(event: string, callback: BusCallback<void>): Unsubscribe {
    const handler: Handler = () => callback();
    this.emitter.on(event, handler);
    return () => this.emitter.off(event, handler);
  }
}
