import { Card } from '@/fairplayer/domain/fan/card/Card';
import { CardRepository } from '@/fairplayer/domain/fan/card/CardRepository';
import { CardToCreate } from '@/fairplayer/domain/fan/card/CardToCreate';
import { MangoPayCardRepository } from '@/fairplayer/secondary/fan/card/MangoPayCardRepository';
import { KriptownCardRepository } from '@/fairplayer/secondary/fan/card/KriptownCardRepository';

export class FairplayerCardRepository implements CardRepository {
  constructor(
    private kriptownCardRepository: KriptownCardRepository,
    private mangoPayCardRepository: MangoPayCardRepository
  ) {}

  list(): Promise<Card[]> {
    return this.kriptownCardRepository.list();
  }

  async create(cardToCreate: CardToCreate): Promise<Card> {
    const cardRegistration = await this.kriptownCardRepository.createCardRegistration(cardToCreate);
    const createdCard = await this.mangoPayCardRepository.createCard(cardToCreate, cardRegistration);

    return {
      id: createdCard.CardId!,
      cardType: cardToCreate.cardType,
      expirationDate: cardToCreate.expirationDate,
      alias: cardToCreate.cardNumber,
      isValid: true,
    };
  }
}
