include ../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line.-align-center
    h3.fp-title {{ t('wallet.currentBalance') }}
  .fp-vertical-spacing--line.-justify-center
    .fp-icon-with-text(v-if='!wallet.isLoading()')
      img.fp-illustration.-xs.-token(alt='Token')
      .fp-icon-with-text--text
        .fp-icon-with-text--text-line
          span.fp-text.-text-semi-bold.-text-lg.-color-shade-900(data-test='wallet.balance.coins-amount')  {{ wallet.value().balance.coins.text }}
          span.fp-text.-color-shade-400(v-if='club.fiatAccepted' data-test='wallet.balance.fiat-value')  / {{ wallet.value().balance.totalCost.text }}
