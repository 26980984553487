include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    form.fp-vertical-spacing#login-form(v-if='!twoFactorNeeded' @submit.prevent='login' data-test='login-form')
      .fp-vertical-spacing--line.-justify-center
        h1.fp-title(data-test='title') {{ t('login.welcome') }}
      .fp-vertical-spacing--line.-justify-center
        .fp-icons-input
          label.fp-icons-input--left-icon(for='username')
            i.fp-icon.fp-glyph-mail.-color-shade-500.-s16
          input.fp-input-text#username(v-model='loginForm.email' type='email' :placeholder=`t('login.email')` autocomplete='username' data-test='email' required)
      .fp-vertical-spacing--line.-justify-center
        PasswordVue#password(placeholder-key='login.password' autocomplete='current-password' @updated='(event) => updatePassword(event.value)' data-test='password')
      .fp-vertical-spacing--line.-align-center(v-if='invalidLogin')
        span.fp-text.-color-danger(data-test='invalid-login') {{ t('login.invalidLogin') }}
      .fp-vertical-spacing--line
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-justify-center(v-if='oldClub && invalidLogin')
            .fp-alert.-info
              +fp-icon('info', { color: 'info', size: '18' })
              .fp-vertical-spacing.-s-sm
                .fp-vertical-spacing--line
                  span.fp-text.-paragraph {{ t('login.oldClubReset') }}
                .fp-vertical-spacing--line
                  router-link.fp-link(:to='{name: \'forgotPassword\'}' data-test='old-club-reset') {{ t('login.oldClubResetButton') }}
          .fp-vertical-spacing--line.-justify-center
            router-link.fp-link.-secondary.-text-sm(:to='{name: \'forgotPassword\'}' data-test='reset-password-view') {{ t('login.forgotPassword') }}
      .fp-vertical-spacing--line
        FairplayerButtonVue(type='submit' icon='log-in' :text=`t('login.loginButton')` form='login-form' :loading='isLogging' data-test='login' expand)
      .fp-vertical-spacing--line
        .fp-separator
          .fp-separator--text
            .fp-separator--text {{ t('login.or') }}
      .fp-vertical-spacing--line
        button.fp-button.-expand.-secondary(@click='googleLogin' type='button' data-test='google-login')
          span.fp-button--icon
            img.fp-illustration.-xs.-google-logo
          | {{ t('login.googleButton') }}
      .fp-vertical-spacing--line.-justify-center
        span.fp-text.-color-shade-500.-text-sm
          | {{ t('login.noAccount') }}&nbsp;
          router-link.fp-link.-secondary(:to='{name: \'signup\'}' data-test='signup-view') {{ t('login.signUpView') }}
    EmailConfirmationVue(v-else :credential='loginForm')
