import { Tokens } from '@/common/domain/token/Tokens';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { CardId } from '@/fairplayer/domain/fan/card/CardId';
import { CardSecurityCode } from '@/fairplayer/domain/fan/card/CardSecurityCode';
import { CardTransactionRedirection } from '@/fairplayer/domain/fan/card/CardTransactionRedirection';
import { WalletRepository } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { BackendWalletRepository } from '@/fairplayer/secondary/fan/wallet/BackendWalletRepository';
import { KriptownWalletRepository } from '@/fairplayer/secondary/fan/wallet/KriptownWalletRepository';

export class FairplayerWalletRepository implements WalletRepository {
  constructor(
    private backendWalletRepository: BackendWalletRepository,
    private kriptownWalletRepository: KriptownWalletRepository
  ) {}

  getForClub(clubSlug: ClubSlug): Promise<Wallet> {
    return this.backendWalletRepository.getForClub(clubSlug);
  }

  computeSuccessFrontendRedirectionUrl(tokens: Tokens): string {
    return this.kriptownWalletRepository.computeSuccessFrontendRedirectionUrl(tokens);
  }

  creditWalletByCard(cardId: CardId, tokens: Tokens, securityCode: CardSecurityCode): Promise<CardTransactionRedirection> {
    return this.kriptownWalletRepository.creditWalletByCard(cardId, tokens, securityCode);
  }

  async purchaseClubCoins(clubSlug: ClubSlug, tokens: Tokens): Promise<void> {
    await this.backendWalletRepository.purchaseClubCoins(clubSlug, tokens);
  }
}
