import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusivePriceVue } from '@/fairplayer/primary/marketplace/exclusive-price';
import { RelativeTimeVue } from '@/common/primary/relative-time';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';

export default defineComponent({
  name: 'ExclusiveDetailHeading',

  components: { ExclusivePriceVue, RelativeTimeVue },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const isFoundation = fromClub(clubRepository.getCurrentClub()).isFoundation;

    return { t, isFoundation };
  },
});
