import { useRoute } from 'vue-router';
import { computed, PropType } from 'vue';
import { MenuItemUi } from '@/common/primary/MenuItem.ui';
import { useI18n } from 'vue-i18n';
import { LoginVue } from '@/common/primary/auth/login';

export default {
  name: 'TopMenu',

  components: {
    LoginVue,
  },

  props: {
    menuItems: {
      type: Array as PropType<MenuItemUi[]>,
      required: true,
    },
    isAuthenticated: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const currentRouterTabName = computed(() => route.meta.tab);

    return { t, currentRouterTabName };
  },
};
