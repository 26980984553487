export interface TosUi {
  id: string;
  checked: boolean;
  linkKey: string;
}

const createTos = (id: string, customLinkKey?: string): TosUi => ({
  id,
  checked: false,
  linkKey: customLinkKey ?? 'tos.link',
});

export const createTosForm = (): TosUi[] => [
  createTos('Fairplayer-fiat-eula'),
  createTos('Fairplayer-fiat-sfc'),
  createTos('Privacy-policy', 'tos.Privacy-policy.link'),
];
