include ../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing(data-test='stepper' v-if='activeStep')
  .fp-vertical-spacing--line(v-if='showProgressBar(activeStep)')
    .fp-progress
      .fp-progress--title(data-test='title') {{ t('stepper.title') }}
      .fp-progress--progression
        .fp-progress-bar
          .fp-progress-bar--bar(data-test='progress-bar' :class='`-fill${stepperPercentage}`')
        .fp-progress--progression-text(data-test='percent') {{ stepperPercentage}}%
  .fp-vertical-spacing--line(data-test='stepper.step.title')
    h1.fp-title {{ t(stepperTitleKey) }}
  .fp-vertical-spacing--line(v-if='stepperTooltipKey')
    .fp-card
      .fp-icon-with-text
        +fp-icon('info', {color: 'info', size: '18'})
        .fp-icon-with-text--text
          .fp-icon-with-text--text-line
            span.fp-text.-paragraph {{ t(stepperTooltipKey) }}
  .fp-vertical-spacing--line
    KeepAlive
      component(:is='activeStep.component' :show-cancel='currentStep !== 0' @canceled='previousStep' @succeed='nextStep' @stepper-information-received='updateStepper')
