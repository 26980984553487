import { createApp } from 'vue';
import App from './common/primary/app/App.vue';
import { createWebappRouter } from './router/router';
import { getProvider, provideAll } from '@/providers';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { createFairplayerI18n } from '@/i18n';
import { initSentry } from '@/sentry';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';
// jhipster-needle-main-ts-import

const app = createApp(App);
const clubRepository = getProvider(clubRepositoryKey);

app.use(createWebappRouter(clubRepository));
provideAll(app.provide);

initSentry(app);
app.use(createFairplayerI18n(clubRepository, getProvider(fanLanguageRepositoryKey)));
// jhipster-needle-main-ts-provider

app.mount('#app');
