import { Order } from '@/fairplayer/domain/fan/order/Order';
import { ExclusiveOrderUi, fromExclusiveOrder } from '@/fairplayer/primary/order-list/ExclusiveOrderUi';
import { LocalDate } from '@/common/domain/LocalDate';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface OrderUi {
  id: string;
  exclusives: ExclusiveOrderUi[];
  date: string;
  originalCost: TokensUi;
  usedCoins: TokensUi;
  finalCost: TokensUi;
}

export const fromOrder = (order: Order): OrderUi => ({
  id: order.id,
  exclusives: order.exclusives.map(fromExclusiveOrder),
  date: LocalDate.fromDate(order.date).toHuman('fr-FR'),
  originalCost: fromTokens(order.originalCost),
  usedCoins: fromTokens(order.usedCoins),
  finalCost: fromTokens(order.finalCost),
});
