include ../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing(data-test='orders')
  .fp-vertical-spacing--line
    h1.fp-title {{ t('orders.title') }}
  template(v-if='!hasOrders()')
    .fp-separator.-secondary
    .fp-vertical-spacing--line
      h3.fp-text.-color-shade-900(data-test='no-orders') {{ t('orders.noOrders') }}
      a.fp-link(@click='goToMarketplace' data-test='redirect-to-marketplace') {{ t('orders.goToMarketplace') }}
  template(v-for='(order, orderIdx) in orderList' :key='`order-${orderIdx}`')
    .fp-vertical-spacing--line
      .fp-separator.-secondary
    template(v-for='(exclusive, exclusiveIdx) in order.exclusives' :key='`order-${orderIdx}-exclusive-${exclusiveIdx}`')
      .fp-vertical-spacing--line
        .fp-vertical-spacing.-s-sm(:data-test='`order-${orderIdx}-exclusive-${exclusiveIdx}`')
          .fp-vertical-spacing--line
            .fp-illustrated-item
              .fp-image.-lg.-rounded
                FairplayerImageVue(:url='exclusive.imageUrl' highlightable data-test='image')
              .fp-illustrated-item--content
                .fp-vertical-spacing.-s-xs
                  .fp-vertical-spacing--line
                    .fp-text.-text-semi-bold.-color-shade-900(data-test='name') {{ exclusive.name }}
                  .fp-vertical-spacing--line
                    .fp-text.-color-shade-500.-text-sm(data-test='date') {{ order.date }}
                  .fp-vertical-spacing--line
                    OrderPriceVue(:order='order')
          .fp-vertical-spacing--line
            a.fp-link(@click='print(exclusive)' data-test='print')
              .fp-icon-with-text
                +fp-icon('print', {size: '16'})
                .fp-icon-with-text--text
                  span.fp-text.-color-shade-600.-text-sm {{ t('orders.print') }}
