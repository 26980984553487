import { Auction } from '@/fairplayer/domain/exclusive/Auction';
import { BackendBid, toBid } from '@/fairplayer/secondary/exclusive/backend/BackendBid';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';

export interface BackendAuction {
  id: string;
  startingPrice: BackendPrice;
  currentPrice: BackendPrice;
  startDate: string;
  endDate: string;
  bids: BackendBid[];
}

export const toAuction = (backendAuction: BackendAuction): Auction => ({
  id: backendAuction.id,
  startingPrice: toTokens(backendAuction.startingPrice),
  currentPrice: toTokens(backendAuction.currentPrice),
  startDate: new Date(backendAuction.startDate),
  endDate: new Date(backendAuction.endDate),
  bids: backendAuction.bids.map(toBid),
});
