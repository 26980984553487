include exclusive-page.loading.mixin.pug

PageVue
  template(v-slot:body)
    NotFoundVue(v-if='displayExclusiveError' :not-found-ui='exclusiveNotFoundUi()')
    template(v-if='exclusiveUi.isLoading()')
      +exclusive-page-loading
    template(v-if='!exclusiveUi.isLoading()')
      .fp-vertical-spacing#exclusive-detail(v-if='exclusiveUi.value().visible')
        .fp-vertical-spacing--line
          ExclusiveDetailVue(:exclusive='exclusiveUi.value()')
        .fp-vertical-spacing--line
          RecommendedExclusivesVue(:current-exclusive='exclusiveUi.value()')
        .fp-vertical-spacing--line(v-for='(linkedClub, index) in club.linkedClubs' :key='index')
          LinkedClubBannerVue(:club='linkedClub')
      HiddenExclusiveVue(v-if='!exclusiveUi.value().visible' :exclusive='exclusiveUi.value()')
  template(v-if='!isAuthenticated.isLoading() && !exclusiveUi.isLoading() && !walletUi.isLoading() && !isInfinite' v-slot:footer)
    .fp-horizontal-spacing.-items-center
      .fp-horizontal-spacing--column(data-test='footer-price')
        ExclusivePriceVue(v-if='exclusiveUi.value().auction' :pricing='exclusiveUi.value().auction.currentPrice' xl vertical)
        ExclusivePriceVue(v-else :pricing='exclusiveUi.value().pricing' xl vertical)
      .fp-horizontal-spacing--column
        FairplayerButtonVue(v-if='exclusiveUi.value().auction' icon='plus' :text='t(\'marketplace.exclusiveDetail.addBid\')' :disabled='buyButtonDisabled' @click='addBid' data-test='add-bid')
        FairplayerButtonVue(v-else-if='club.isFoundation' icon='coin' :text='t(\'marketplace.exclusiveDetail.support\')' :disabled='buyButtonDisabled' @click='buy' data-test='exclusive-page.buy')
        FairplayerButtonVue(v-else icon='shopping-cart' :text='t(\'marketplace.exclusiveDetail.buy\')' :disabled='buyButtonDisabled' @click='buy' data-test='exclusive-page.buy')
