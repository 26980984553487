include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line
  .fp-horizontal-spacing.-equivalent.-s-sm
    .fp-horizontal-spacing--column
      .fp-card
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.startingPrice') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auction.startingPrice.totalCost.text }}
    .fp-horizontal-spacing--column
      .fp-card.-highlighted
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.lastBid') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auction.currentPrice.totalCost.text }}
    .fp-horizontal-spacing--column
      .fp-card
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-sm {{ t('marketplace.exclusiveDetail.auction.bids') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-semi-bold {{ exclusive.auction.bids.length }}
.fp-vertical-spacing--line(v-if='millisecondsLeft > 0' data-test='time-left')
  .fp-card
    .fp-horizontal-spacing.-equivalent
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ daysLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.days') }}
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ hoursLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.hours') }}
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ minutesLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.minutes') }}
      .fp-horizontal-spacing--column
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-lg.-text-semi-bold {{ secondsLeft }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-text-xs.-text-uppercase {{ t('marketplace.exclusiveDetail.auction.countdown.seconds') }}
.fp-vertical-spacing--line
  .fp-vertical-spacing.-s-sm
    .fp-vertical-spacing--line
      h2.fp-title {{ t('marketplace.exclusiveDetail.auction.bidders') }}
    .fp-vertical-spacing--line(v-for='(bid, index) in exclusive.auction.bids' :key='bid.id' :data-test='`bid-${index}`')
      .fp-card(:class='{"-highlighted": index === 0}')
        .fp-horizontal-spacing.-items-center.-s-sm
          .fp-horizontal-spacing--column
            .fp-image.-circled
              img(:src='bid.bidder.pictureUrl' alt='Picture')
          .fp-horizontal-spacing--column.-expand
            .fp-vertical-spacing.-s-xs
              .fp-vertical-spacing--line
                span.fp-text.-text-sm.-text-semi-bold {{ bid.bidder.firstName }} {{ bid.bidder.lastName }}
              .fp-vertical-spacing--line
                RelativeTimeVue(:date='bid.date' sm)
          .fp-horizontal-spacing--column
            span.fp-text.-text-semi-bold {{ bid.price.totalCost.text }}
