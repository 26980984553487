import { createI18n } from 'vue-i18n';
import fr from './locales/fr.json';
import en from './locales/en.json';
import { Club } from '@/fairplayer/domain/club/Club';
import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { FanLanguageRepository } from '@/common/domain/FanLanguageRepository';

type MessageSchemaEn = typeof en;
type MessageSchemaFr = typeof fr;

const replaceClubTranslations = (translated: string) => (club: Club) =>
  translated.replaceAll('#club#', `"${club.name}"`).replaceAll('#ticker#', club.ticker);

export const replaceSelectedClubTranslations = (translated: string, clubRepository: ClubRepository) =>
  clubRepository.findCurrentClub().map(replaceClubTranslations(translated)).orElse(translated);

export const createFairplayerI18n = (clubRepository: ClubRepository, fanLanguageRepository: FanLanguageRepository) => {
  return createI18n<[MessageSchemaEn & MessageSchemaFr], 'en' | 'fr'>({
    locale: fanLanguageRepository.getCurrentLanguage(),
    legacy: false,
    postTranslation: translated => replaceSelectedClubTranslations(translated as string, clubRepository),
    messages: {
      fr,
      en,
    },
  });
};
