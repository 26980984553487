import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { WalletBalanceVue } from '@/fairplayer/primary/wallet/wallet-balance';

export default {
  name: 'WalletActions',

  components: { WalletBalanceVue },

  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;

    const fan = ref<Fan>();

    const disabledBuyButton = computed(() => fan.value?.kycStatus !== KycStatus.KYC_VALIDATED);

    const club = fromClub(clubRepository.getCurrentClub());

    onMounted(async () => {
      fan.value = await fanRepository.getForClub(clubRepository.getCurrentClub());
    });

    const goToCardsList = () => {
      if (!disabledBuyButton.value) {
        router.push({ name: 'cardsList' });
      }
    };
    return {
      club,
      disabledBuyButton,
      t,
      goToCardsList,
    };
  },
};
