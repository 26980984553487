import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { ClubSlugNotFoundError } from '@/fairplayer/secondary/club/ClubSlugNotFoundError';
import { Optional } from '@/common/domain/Optional';

const clubSlugMatcher = /clubs(\/|%2F)([\da-z-]+)/;

const FAIRPLAYER_DOMAINS = ['localhost', 'app.fairplayerlance.fr', 'app.fairplayer.co', 'fairplayer.kriptown.com'];

export class UrlParserClubSlugRepository {
  constructor(private window: Window) {}

  findCurrentClubSlug(): Optional<ClubSlug> {
    if (this.isCustomClubDomain()) {
      const parts = this.window.location.hostname.split('.');
      return Optional.of(`${parts[1]}-${parts[0]}`);
    }

    return this.extractFromFairplayerUri();
  }

  private extractFromFairplayerUri() {
    const href = this.window.location.href;

    const matchingResult = href.match(clubSlugMatcher);

    return Optional.ofUndefinable(matchingResult).map(result => result[2]);
  }

  getCurrentClubSlug(): ClubSlug {
    return this.findCurrentClubSlug().orElseThrow(() => new ClubSlugNotFoundError());
  }

  isCustomClubDomain(): boolean {
    return FAIRPLAYER_DOMAINS.every(domain => !this.window.location.hostname.includes(domain));
  }
}
