include ../../../../common/primary/carousel/loading-carousel.mixin.pug

PageVue
  template(v-slot:header)
    ClubHomepageHeaderVue(:is-authenticated='isAuthenticated' :is-not-referred='isNotReferred' :has-exclusives='hasExclusives' :has-programs='hasPrograms')
  template(v-slot:body v-if='displayAppContent')
    .fp-vertical-spacing.-s-lg(v-if='club.isMarketplace')
      .fp-vertical-spacing--line(v-for='(linkedClub, index) in club.linkedClubs' :key='index')
        LinkedClubBannerVue(:club='linkedClub')
      .fp-vertical-spacing--line(v-if='club.state === "UPCOMING"' data-test='upcoming-alert')
        UpcomingClubAlertVue
      .fp-vertical-spacing--line(v-if='exclusives.isLoading() || hasExclusives')
        template(v-if='exclusives.isLoading()')
          +fp-loading-carousel
        ExclusivesCarouselVue(v-if='hasExclusives' :items='carouselExclusives')
      .fp-vertical-spacing--line(v-if='showTokensDocumentation' data-test='feature-card-list')
        FeatureCardListVue
      .fp-vertical-spacing--line.-justify-center(v-if='!isAuthenticated.isLoading() && isAuthenticated.value() && club.coinsBuyable')
        router-link.fp-link.-no-text-decoration(:to='{name: \'wallet\'}')
          FairplayerButtonVue(icon='coin' :text='t("homepage.buyTokens")' data-test='buy-tokens-second')
      .fp-vertical-spacing--line.-justify-center(v-if='!isAuthenticated.isLoading() && !isAuthenticated.value()')
        LoginVue(:redirect-to-kyc='true' translate-key='homepage.login' data-test='login-second')
      .fp-vertical-spacing--line(v-if='showKriptownFaq' data-test='kriptown-faq')
        KriptownFaqVue
      .fp-vertical-spacing--line(v-if='showOffChainFaq' data-test='off-chain-faq')
        OffChainFaqVue
    .fp-vertical-spacing.-s-lg(v-else-if='isValidated')
      .fp-vertical-spacing--line(v-if='members.isLoading() || hasMembers' data-test='members')
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title {{ t('homepage.members') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "members"}') {{ t('homepage.seeAll') }}
          .fp-vertical-spacing--line
            MembersCarouselVue(v-if='hasMembers' :members='members.value()')
            template(v-if='members.isLoading()')
              +fp-loading-carousel
      .fp-vertical-spacing--line(v-if='hasAuctions' data-test='auctions')
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line
            h2.fp-title {{ t('homepage.auctions') }}
          .fp-vertical-spacing--line
            ExclusivesCarouselVue(:items='exclusivesWithAuction')
      .fp-vertical-spacing--line(v-if='hasPosts || posts.isLoading()')
        .fp-vertical-spacing(data-test='posts')
          .fp-vertical-spacing--line
            .fp-horizontal-spacing.-items-end
              .fp-horizontal-spacing--column.-expand
                h2.fp-title(data-test='title') {{ t('homepage.recentNews') }}
              .fp-horizontal-spacing--column
                router-link.fp-link.-secondary(:to='{name: "newsfeed"}') {{ t('homepage.seeAllNews') }}
          PostsListVue(v-if='hasPosts' :posts='posts.value()')
          template(v-if='posts.isLoading()')
            .fp-vertical-spacing--line(v-for='(_, index) in 4')
              .fp-placeholder.-height-md
      .fp-vertical-spacing--line(v-if='hasPrograms')
        .fp-vertical-spacing.-s-sm
          .fp-vertical-spacing--line
            h2.fp-title {{ t('homepage.programs') }}
          .fp-vertical-spacing--line
            span.fp-text {{ t('homepage.programsSubtitle') }}
          .fp-vertical-spacing--line
            ProgramsCarouselVue(:programs='programs.value()')
      .fp-vertical-spacing--line
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            h2.fp-title {{ t('homepage.support') }}
          .fp-vertical-spacing--line(data-test='one-time-donation')
            .fp-card
              .fp-vertical-spacing
                .fp-vertical-spacing--line
                  span.fp-text {{ t('homepage.oneTimeDonation') }}
                template(v-if='hasExclusiveWithoutAuctions')
                  .fp-vertical-spacing--line
                    span.fp-text {{ t('homepage.oneTimeDonationSubtitle') }}
                  .fp-vertical-spacing--line(v-for='(exclusive, index) in exclusivesWithoutAuction' :key='index' data-test='exclusives')
                    .fp-horizontal-spacing.-items-center.-s-sm
                      .fp-horizontal-spacing--column
                        img.fp-image.-rounded(:src='exclusive.imageUrl')
                      .fp-horizontal-spacing--column {{ exclusive.title }}
                .fp-vertical-spacing--line
                  router-link.fp-link.-no-text-decoration(:to='{name: \'oneTimeDonation\'}')
                    FairplayerButtonVue(:text='t(\'homepage.oneTimeDonationAction\')' icon='hand-heart' data-test='go-to-one-time-donation')
