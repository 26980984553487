import { defineComponent, inject } from 'vue';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'Logout',

  setup() {
    const authentication = inject(authenticationKey)!;
    const { t } = useI18n();
    const router = useRouter();
    const logout = async () => {
      await authentication.logout(true);
      await router.push({ path: '/' });
    };

    return {
      logout,
      t,
    };
  },
});
