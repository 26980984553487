import { authenticationKey } from '@/common/domain/auth/Authentication';
import { FairplayerButtonVue } from '@/common/primary/button';
import { MailToContactVue } from '@/fairplayer/primary/email';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { ExclusivesListFilterModalVue } from '@/fairplayer/primary/marketplace/exclusives-list/exclusives-list-filter-modal';
import { ExclusivesListFiltersVue } from '@/fairplayer/primary/marketplace/exclusives-list/exclusives-list-filters';
import { ExclusivesFilters } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFilters';
import { exclusivesFiltersStorageKey } from '@/fairplayer/primary/marketplace/exclusives-list/ExclusivesFiltersStorage';
import { filtersToSave, getFilters, hasExclusivesFilters } from '@/fairplayer/primary/marketplace/exclusives-list/handleFilters';
import { sortByExclusiveFilters } from '@/fairplayer/primary/marketplace/exclusives-list/sortByFilters';
import { PageVue } from '@/fairplayer/primary/page';
import { Loader } from '@/loader/primary/Loader';
import { computed, inject, onMounted, Ref, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { ExclusivesListAlertVue } from '@/fairplayer/primary/marketplace/exclusives-list/exclusives-list-alert';
import { LinkedClubBannerVue } from '@/fairplayer/primary/club/linked-club-banner';
import { exclusivesListFiltersBottomModal } from '@/common/primary/bottom-modal/BottomModals';
import { bottomModalBusKey } from '@/common/domain/bottom-modal/BottomModalBus';
import { loggerKey } from '@/common/domain/Logger';
import { fromExclusiveUi } from '@/common/primary/presentation-card/PresentationCardItem.ui';
import { PresentationCardVue } from '@/common/primary/presentation-card';

const makeDefaultFilters = (overriddenValues?: Partial<ExclusivesFilters>): ExclusivesFilters => ({
  categories: [ExclusiveCategory.ACCESS, ExclusiveCategory.COLLECTIBLE, ExclusiveCategory.MOMENT],
  sortBy: 'price',
  sortOrder: 'desc',
  ...overriddenValues,
});

export default {
  name: 'ExclusivesList',

  components: {
    ClubLogoVue,
    PresentationCardVue,
    ExclusivesListFilterModalVue,
    ExclusivesListFiltersVue,
    ExclusivesListAlertVue,
    FairplayerButtonVue,
    MailToContactVue,
    LinkedClubBannerVue,
    PageVue,
  },

  setup() {
    const authentication = inject(authenticationKey)!;
    const bottomModalBus = inject(bottomModalBusKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const exclusivesFiltersStorage = inject(exclusivesFiltersStorageKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;
    const router = useRouter();
    const route = useRoute();
    const { d, t } = useI18n();

    const club = fromClub(clubRepository.getCurrentClub());
    const exclusivesList = ref(Loader.loading<ExclusiveUi[]>());
    const isAuthenticated = ref(false);
    const currentFilters = ref<ExclusivesFilters>(makeDefaultFilters());

    const exclusives = computed(() =>
      sortByExclusiveFilters(exclusivesList.value.value(), currentFilters.value).map(exclusive => fromExclusiveUi(exclusive, t, d))
    );
    const hasFilters = computed(() => hasExclusivesFilters(currentFilters.value));
    const alertsKeys: Ref<string[]> = ref([]);

    watch(
      () => [route.query],
      () => {
        const filters = getFilters(route.query);
        setFilters(filters);
      }
    );

    onMounted(async () => {
      await loadExclusiveList();
      if (router.currentRoute.value.hash) {
        windowScroller.scrollToId(router.currentRoute.value.hash.substring(1));
      }
      retrieveFilters();
      await retrieveAuthentication();
      addClubStateAlertKeys();
    });

    const loadExclusiveList = async (): Promise<void> => {
      try {
        const exclusivesListResponse = await exclusiveRepository.listBySlug(clubRepository.getCurrentSlug());
        exclusivesList.value.loaded(exclusivesListResponse.map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub())));
        if (!exclusivesList.value.value().length) {
          alertsKeys.value.push('marketplace.alert.emptyExclusives');
        }
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusivesList.value.loaded([]);
      }
    };

    const openFiltersModal = () => {
      const modal = exclusivesListFiltersBottomModal();
      bottomModalBus.open({ component: modal, titleKey: 'marketplace.filters', icon: 'filter', options: { filters: currentFilters } });
    };

    const setFilters = (filters: ExclusivesFilters) => {
      currentFilters.value = filters;
      exclusivesFiltersStorage.save(filters);
    };

    const retrieveFilters = () => {
      currentFilters.value = getFilters(route.query);
      if (!hasExclusivesFilters(currentFilters.value)) {
        retrieveFiltersFromStorage();
      }
    };

    const retrieveAuthentication = async () => {
      isAuthenticated.value = await authentication.isAuthenticated();
    };

    const retrieveFiltersFromStorage = () => {
      exclusivesFiltersStorage.get().ifPresent(filters => {
        currentFilters.value = filters;
        router.push({
          query: filtersToSave(filters),
        });
      });
    };

    const addClubStateAlertKeys = () => {
      if (exclusivesList.value.value().length === 0) {
        return;
      }

      if (club.state === ClubState.UPCOMING) {
        alertsKeys.value.push('marketplace.alert.clubUpcoming');
      }
      if (club.state === ClubState.STAND_BY) {
        alertsKeys.value.push('marketplace.alert.clubStandBy');
      }
    };

    return {
      alertsKeys,
      club,
      currentFilters,
      exclusivesList,
      exclusives,
      t,
      hasFilters,
      openFiltersModal,
    };
  },
};
