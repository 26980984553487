import { defineAsyncComponent } from 'vue';

export const exclusivesListFiltersBottomModal = () =>
  defineAsyncComponent(
    () => import('@/fairplayer/primary/marketplace/exclusives-list/exclusives-list-filter-modal/ExclusivesListFilterModal.vue')
  );

export const paymentTypeSelectorBottomModal = () =>
  defineAsyncComponent(
    () => import('@/fairplayer/primary/marketplace/exclusive-page/payment-type-selector-modal/PaymentTypeSelectorModal.vue')
  );

export const confirmPurchaseBottomModal = () =>
  defineAsyncComponent(() => import('@/fairplayer/primary/marketplace/exclusive-page/confirm-purchase-modal/ConfirmPurchaseModal.vue'));
