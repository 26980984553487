import { InvalidCardExpirationDateError } from '@/fairplayer/domain/fan/card/InvalidCardExpirationDateError';

const LENGTH_OF_YEAR = 2;
const MONTH_YEAR_SEPARATOR = '/';

const invalidDate = () => {
  throw new InvalidCardExpirationDateError();
};

const validateAndExtractInput = (rawInput: string | number) => {
  const input = (rawInput || '')
    .toString()
    .replaceAll(MONTH_YEAR_SEPARATOR, '')
    .replace(/(\d+)(20)(\d\d)/, '$1$3');

  const length = input.length;

  if (length < 3 || length > 4) {
    invalidDate();
  }

  return input;
};

export class CardExpirationDate {
  readonly month: number;
  readonly year: number;

  constructor(rawInput: string | number) {
    const input = validateAndExtractInput(rawInput);

    const lengthOfMonth = input.length - LENGTH_OF_YEAR;
    this.month = +input.substring(0, lengthOfMonth);
    this.year = +input.substring(lengthOfMonth);

    this.validateMonth();
  }

  private validateMonth() {
    if (this.month > 12) {
      invalidDate();
    }
  }

  toHuman(): string {
    return `${this.month}/${this.year}`.padStart(5, '0');
  }

  toComputer(): string {
    return this.toHuman().replaceAll('/', '');
  }
}
