import { AddCardFormVue } from '@/fairplayer/primary/wallet/cards-list/add-card-form';
import { CardUi, fromCard } from '@/fairplayer/primary/wallet/cards-list/Card.ui';
import { cardRepositoryKey } from '@/fairplayer/domain/fan/card/CardRepository';
import { Loader } from '@/loader/primary/Loader';
import { PageVue } from '@/fairplayer/primary/page';
import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';

export default {
  name: 'CardsList',

  components: {
    AddCardFormVue,
    PageVue,
    FairplayerButtonVue,
  },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const cardRepository = inject(cardRepositoryKey)!;

    const cards = ref(Loader.loading<CardUi[]>());
    const selectedCard = ref<CardUi>();

    const addCardFormVisible = ref(false);

    onMounted(async () => {
      cardRepository.list().then(repoCards => cards.value.loaded(repoCards.map(fromCard)));
    });

    const addCreatedCard = (createdCard: CardUi) => {
      cards.value.value().push(createdCard);

      hideAddCardForm();
    };

    const showAddCardForm = () => (addCardFormVisible.value = true);

    const hideAddCardForm = () => (addCardFormVisible.value = false);

    const continueToCredit = async () => {
      await router.push({ name: 'creditWallet', params: { cardId: selectedCard.value!.id } });
    };

    return {
      cards,
      addCreatedCard,
      addCardFormVisible,
      continueToCredit,
      showAddCardForm,
      hideAddCardForm,
      selectedCard,
      t,
    };
  },
};
