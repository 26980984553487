import { extractTokens } from '@/fairplayer/primary/wallet/payment-processing/extractTokens';
import { Coins } from '@/common/domain/token/Coins';
import { Tokens } from '@/common/domain/token/Tokens';
import { fromTokens } from '@/common/primary/token/Tokens.ui';
import { PageVue } from '@/fairplayer/primary/page';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';

export default {
  name: 'PaymentConfirmed',

  components: { PageVue, FairplayerButtonVue },

  setup(props: any, { emit }: any) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();

    const tokens = extractTokens(route.query);
    const tokensUi = fromTokens(tokens, { fiatValueDigit: 0 });

    const extractBalance = (amount: number) =>
      fromTokens(Tokens.of(new Coins(amount, tokens.coins.ticker)).withTokenCost(tokens.tokenCost));

    const initialBalance = extractBalance(+route.query.initialCoinsAmount!);
    const availableBalance = extractBalance(+route.query.availableCoinsAmount!);

    const goToMarketplace = async () => {
      await router.push({ name: 'marketplace' });
    };

    onMounted(() => {
      emit('refresh-layout');
    });

    return {
      t,
      initialBalance,
      tokensUi,
      availableBalance,
      goToMarketplace,
    };
  },
};
