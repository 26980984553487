.fp-responsive-grid.-cols2.-cols1-up-md
  .fp-responsive-grid--column
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        h2.fp-title {{ t('faq.faqTitle') }}
      .fp-vertical-spacing--line
        .fp-text {{ t('faq.faqSubtitle') }}
      .fp-vertical-spacing--line
        FaqCardVue(card-id='exclusiveDefinition')
      .fp-vertical-spacing--line
        FaqCardVue(:card-id='tokenDefinitionId')
      .fp-vertical-spacing--line
        FaqCardVue(card-id='switchClub' link='clubsPage')
      .fp-vertical-spacing--line
        FaqCardVue(v-if='showExclusiveInFiat' card-id='exclusiveInFiat' data-test='exclusive-in-fiat')
  .fp-responsive-grid--column
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        h2.fp-title {{ t('faq.otherQuestionsTitle') }}
      .fp-vertical-spacing--line
        .fp-text {{ t('faq.otherQuestionsSubtitle') }}
      .fp-vertical-spacing--line
        FaqCardVue(card-id='needWallet')
      .fp-vertical-spacing--line
        FaqCardVue(card-id='tokenOnchain')
      .fp-vertical-spacing--line
        FaqCardVue(card-id='tokenRefund')
      .fp-vertical-spacing--line
        FaqCardVue(card-id='tokenValue')
