import { Authentication } from '@/common/domain/auth/Authentication';
import { Tokens } from '@/common/domain/token/Tokens';
import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Wallet } from '@/fairplayer/domain/Wallet';

export class BackendWalletRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  getForClub(clubSlug: ClubSlug): Promise<Wallet> {
    return this.backendCaller
      .get<BackendPrice>(`api/clubs/${clubSlug}/fans/me/balance`)
      .then(response => new Wallet(toTokens(response.data)));
  }

  async purchaseClubCoins(clubSlug: ClubSlug, tokens: Tokens): Promise<void> {
    const authenticatedFan = await this.authentication.authenticatedFan();

    await this.backendCaller.post<void>(`api/fans/${encodeURIComponent(authenticatedFan.username)}/coins-purchases`, {
      clubSlug,
      coinsAmount: tokens.coins.amount,
    });
  }
}
