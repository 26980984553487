import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { RouteRecordRaw } from 'vue-router';
import { LoginRedirectCallbackVue } from '@/login/redirect-callback';
import { TwoFactorAuthenticationVue } from '@/login/two-factor-authentication';
import { ClubLayoutVue } from '@/fairplayer/primary/club-layout';
import { ClubHomepageVue } from '@/fairplayer/primary/home/club-homepage';
import { RouterTab } from '@/router/RouterTab';
import { LoginPageVue } from '@/common/primary/auth/login-page';
import { SignupVue } from '@/common/primary/auth/signup';
import { ForgotPasswordVue } from '@/common/primary/auth/forgot-password';
import { WalletPageVue } from '@/fairplayer/primary/wallet';
import { CardsListVue } from '@/fairplayer/primary/wallet/cards-list';
import { CreditWalletFormVue } from '@/fairplayer/primary/wallet/cards-list/credit-wallet-form';
import { MembersListVue } from '@/fairplayer/primary/member/members-list';
import { ChatPageVue } from '@/fairplayer/primary/member/chat-page';
import { NewsfeedVue } from '@/fairplayer/primary/newsfeed';
import { PostPageVue } from '@/fairplayer/primary/newsfeed/post-page';
import { PaymentProcessingVue } from '@/fairplayer/primary/wallet/payment-processing';
import { PaymentConfirmedVue } from '@/fairplayer/primary/wallet/payment-confirmed';
import { PaymentFailedVue } from '@/fairplayer/primary/wallet/payment-failed';
import { ProgramsListVue } from '@/fairplayer/primary/programs/programs-list';
import { ProgramPageVue } from '@/fairplayer/primary/programs/program-page';
import { ExclusivesListVue } from '@/fairplayer/primary/marketplace/exclusives-list';
import { ExclusivePageVue } from '@/fairplayer/primary/marketplace/exclusive-page';
import { PurchaseConfirmationVue } from '@/fairplayer/primary/marketplace/purchase-confirmation';
import { PurchaseConfirmedVue } from '@/fairplayer/primary/marketplace/purchase-confirmed';
import { ProfileVue } from '@/fairplayer/primary/profile';
import { KycFormVue } from '@/fairplayer/primary/kyc-form';
import { GiveawayParticipationPageVue } from '@/fairplayer/primary/giveaway-participation';
import { NotFoundVue } from '@/common/primary/not-found';
import { HomepageVue } from '@/fairplayer/primary/homepage';
import { ClubsPageVue } from '@/fairplayer/primary/clubs-page';
import { OneTimeDonationVue } from '@/fairplayer/primary/one-time-donation';
import { OneTimeDonationPaymentConfirmedVue } from '@/fairplayer/primary/one-time-donation/payment-confirmed';

export const HOMEPAGE_ROUTE_NAME = 'homepage';

const routesDependingOnCustomDomain = (clubRepository: ClubRepository) => {
  if (clubRepository.isCustomClubDomain()) {
    return [];
  }

  return [
    {
      path: '/',
      name: HOMEPAGE_ROUTE_NAME,
      component: HomepageVue,
    },
    {
      path: '/clubs',
      name: 'clubsPage',
      component: ClubsPageVue,
    },
  ];
};

const computeClubLayoutPath = (clubRepository: ClubRepository) => {
  if (clubRepository.isCustomClubDomain()) {
    return '/';
  }

  return '/clubs/:slug';
};

export const computeRoutes = (clubRepository: ClubRepository): Readonly<RouteRecordRaw[]> => {
  return [
    ...routesDependingOnCustomDomain(clubRepository),
    {
      path: '/login/callback',
      name: 'loginRedirectCallback',
      component: LoginRedirectCallbackVue,
    },
    {
      path: '/login/2fa',
      name: 'twoFactorAuthentication',
      component: TwoFactorAuthenticationVue,
      meta: {
        requireAuthentication: true,
      },
    },
    {
      path: computeClubLayoutPath(clubRepository),
      component: ClubLayoutVue,
      children: [
        {
          path: '',
          name: 'clubHomepage',
          component: ClubHomepageVue,
          meta: {
            tab: RouterTab.HOMEPAGE,
          },
        },
        {
          path: 'login',
          name: 'login',
          component: LoginPageVue,
        },
        {
          path: 'signup',
          name: 'signup',
          component: SignupVue,
        },
        {
          path: 'forgot-password',
          name: 'forgotPassword',
          component: ForgotPasswordVue,
        },
        {
          path: 'wallet',
          name: 'wallet',
          component: WalletPageVue,
          meta: {
            tab: RouterTab.WALLET,
          },
        },
        {
          path: 'cards',
          name: 'cardsList',
          component: CardsListVue,
          meta: {
            tab: RouterTab.WALLET,
            requireAuthentication: true,
          },
        },
        {
          path: 'cards/:cardId/credit',
          name: 'creditWallet',
          component: CreditWalletFormVue,
          meta: {
            tab: RouterTab.WALLET,
            requireAuthentication: true,
          },
        },
        {
          path: 'members',
          name: 'members',
          component: MembersListVue,
          meta: {
            tab: RouterTab.MEMBERS,
            requireAuthentication: true,
          },
        },
        {
          path: 'members/:memberId',
          name: 'chatPage',
          component: ChatPageVue,
          props: true,
          meta: {
            tab: RouterTab.MEMBERS,
            requireAuthentication: true,
          },
        },
        {
          path: 'one-time-donation',
          name: 'oneTimeDonation',
          component: OneTimeDonationVue,
        },
        {
          path: 'one-time-donation/payment-confirmed',
          name: 'oneTimeDonationPaymentConfirmed',
          component: OneTimeDonationPaymentConfirmedVue,
        },
        {
          path: 'news',
          name: 'newsfeed',
          component: NewsfeedVue,
          meta: {
            tab: RouterTab.NEWS,
          },
        },
        {
          path: 'news/:postSlug',
          name: 'postPage',
          component: PostPageVue,
          meta: {
            tab: RouterTab.NEWS,
          },
        },
        {
          path: 'payment-processing',
          name: 'paymentProcessing',
          component: PaymentProcessingVue,
          meta: {
            tab: RouterTab.WALLET,
            hideAnnouncement: true,
            requireAuthentication: true,
          },
        },
        {
          path: 'payment-confirmed',
          name: 'paymentConfirmed',
          component: PaymentConfirmedVue,
          meta: {
            tab: RouterTab.WALLET,
            requireAuthentication: true,
          },
        },
        {
          path: 'payment-failed',
          name: 'paymentFailed',
          component: PaymentFailedVue,
          meta: {
            tab: RouterTab.WALLET,
            requireAuthentication: true,
          },
        },
        {
          path: 'programs',
          name: 'programs',
          component: ProgramsListVue,
          meta: {
            tab: RouterTab.PROGRAMS,
          },
        },
        {
          path: 'programs/:programSlug',
          name: 'programPage',
          component: ProgramPageVue,
          meta: {
            tab: RouterTab.PROGRAMS,
          },
        },
        {
          path: 'marketplace',
          name: 'marketplace',
          component: ExclusivesListVue,
          meta: {
            tab: RouterTab.MARKETPLACE,
          },
        },
        {
          path: 'exclusives/:exclusiveSlug',
          name: 'exclusiveDetail',
          component: ExclusivePageVue,
          meta: {
            tab: RouterTab.MARKETPLACE,
          },
        },
        {
          path: 'exclusives/:exclusiveSlug/purchase-confirmation',
          name: 'purchaseConfirmation',
          component: PurchaseConfirmationVue,
          meta: {
            tab: RouterTab.MARKETPLACE,
            requireAuthentication: true,
          },
        },
        {
          path: 'exclusives/:exclusiveSlug/purchase-confirmed',
          name: 'purchaseConfirmed',
          component: PurchaseConfirmedVue,
          meta: {
            tab: RouterTab.MARKETPLACE,
            requireAuthentication: true,
          },
        },
        {
          path: 'profile',
          name: 'profile',
          component: ProfileVue,
          meta: {
            tab: RouterTab.PROFILE,
            hideAnnouncement: true,
            requireAuthentication: true,
          },
        },
        {
          path: 'kyc',
          name: 'kyc',
          component: KycFormVue,
          meta: {
            hideAnnouncement: true,
            requireAuthentication: true,
          },
        },
        {
          path: 'giveaway',
          name: 'giveaway',
          component: GiveawayParticipationPageVue,
          meta: {
            hideAnnouncement: true,
          },
        },
        {
          path: ':pathMatch(.*)*',
          name: 'notFound',
          component: NotFoundVue,
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'rootNotFound',
      component: NotFoundVue,
    },
  ];
};
