import { defineComponent, PropType, ref } from 'vue';
import { CarouselVue } from '@/common/primary/carousel';
import { PresentationCardItem } from '@/common/primary/presentation-card/PresentationCardItem.ui';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ProgramsCarousel',

  components: { CarouselVue },

  props: {
    programs: {
      type: Array as PropType<PresentationCardItem[]>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const currentIndex = ref(0);

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index;
    };

    return {
      t,
      setCurrentIndex,
      currentIndex,
    };
  },
});
