include ../../../../common/primary/icon/icon.mixin.pug

.fp-horizontal-spacing(v-if='fiatAccepted')
  .fp-horizontal-spacing--column(v-if='order.usedCoins.coins.amount > 0')
    span.fp-text.-color-shade-400.-text-semi-bold.-text-line-through(data-test='original-cost') {{ order.originalCost.totalCost.text }}
  .fp-horizontal-spacing--column
    span.fp-text.-color-shade-900.-text-semi-bold(data-test='final-cost') {{ order.finalCost.totalCost.text }}
.fp-icon-with-text(v-if='!fiatAccepted')
  img.fp-illustration.-xs.-token(alt='Token')
  .fp-icon-with-text--text
    .fp-icon-with-text--text-line
      span.fp-text.-color-shade-900.-text-semi-bold(data-test='coins-price') {{ order.originalCost.coins.text }}
