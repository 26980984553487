import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerImageVue } from '@/common/primary/fairplayer-image';
import { MemberUi } from '@/fairplayer/primary/member/Member.ui';
import { FairplayerFallbackImageUi } from '@/common/primary/fairplayer-image/FairplayerFallbackImage.ui';

export default defineComponent({
  name: 'MemberCard',

  components: {
    FairplayerImageVue,
  },

  props: {
    member: {
      type: Object as PropType<MemberUi>,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();

    return {
      FairplayerFallbackImageUi,
      t,
    };
  },
});
