import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';
import { PaymentTypeAvailability } from '@/fairplayer/domain/club/PaymentTypeAvailability';

export interface BackendPaymentTypeAvailability {
  accepted: boolean;
  limit: BackendFiat;
}

export const toPaymentTypeAvailability = (backendPaymentTypeAvailability: BackendPaymentTypeAvailability): PaymentTypeAvailability => ({
  accepted: backendPaymentTypeAvailability.accepted,
  limit: toFiat(backendPaymentTypeAvailability.limit),
});
