import { inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { donationRepositoryKey } from '@/fairplayer/domain/donation/DonationRepository';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { globalWindowKey } from '@/common/domain/Window';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { FairplayerButtonVue } from '@/common/primary/button';
import { PageVue } from '@/fairplayer/primary/page';
import { euro } from '@/common/domain/token/Fiat';
import { loggerKey } from '@/common/domain/Logger';
import { Loader } from '@/loader/primary/Loader';
import { fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { fromExclusiveUi, PresentationCardItem } from '@/common/primary/presentation-card/PresentationCardItem.ui';
import { PresentationCardVue } from '@/common/primary/presentation-card';

export default {
  name: 'OneTimeDonation',

  components: {
    PageVue,
    FairplayerButtonVue,
    PresentationCardVue,
  },

  setup() {
    const { d, t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const donationRepository = inject(donationRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const globalWindow = inject(globalWindowKey)!;
    const logger = inject(loggerKey)!;

    const exclusives = ref(Loader.loading<PresentationCardItem[]>());
    const amount = ref(1000);
    const isCreatingOneTimeDonation = ref(false);

    const createDonation = async () => {
      isCreatingOneTimeDonation.value = true;
      const clubSlug = clubRepository.getCurrentSlug();
      const currentLocation = globalWindow.location.href;
      donationRepository
        .create({ clubSlug, amount: euro(+amount.value), currentLocation })
        .then(({ url }) => pageRedirecter.navigateTo(url))
        .catch((error: any) => {
          logger.error('Failed to create donation', error);
          isCreatingOneTimeDonation.value = false;
        });
    };

    const loadExclusives = async (): Promise<void> => {
      try {
        const exclusivesResponse = await exclusiveRepository.listBySlug(clubRepository.getCurrentSlug());
        exclusives.value.loaded(
          exclusivesResponse
            .filter(exclusive => exclusive.auction.isEmpty())
            .map(exclusive => fromExclusive(exclusive, clubRepository.getCurrentClub()))
            .map(exclusive => fromExclusiveUi(exclusive, d, t))
        );
      } catch (error: any) {
        logger.error('Failed to retrieve exclusives', error);
        exclusives.value.loaded([]);
      }
    };

    onMounted(() => loadExclusives());

    return {
      exclusives,
      amount,
      createDonation,
      isCreatingOneTimeDonation,
      t,
    };
  },
};
