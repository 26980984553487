include ../../common/primary/icon/icon.mixin.pug

.fp-layout
  .fp-layout--body
    PageVue
      template(v-slot:body)
        .fp-vertical-spacing(data-test='two-factor-authentication')
          .fp-vertical-spacing--line.-justify-center
            ClubLogoVue
          .fp-vertical-spacing--line.-align-center
            h1.fp-title {{ t('loginRedirect.title') }}
          .fp-vertical-spacing--line.-align-center
            span.fp-text.-paragraph {{ t('loginRedirect.description') }}
          form.fp-vertical-spacing--line.-align-center(@submit.prevent='sendTwoFactorAuthentication' data-test='form')
            label.fp-field
              | {{ t('loginRedirect.code') }}
              input.fp-input-text(type='text' v-model='twoFactorAuthenticationCode' data-test='two-factor-authentication-code')
          .fp-vertical-spacing--line.-align-center(v-if='invalidTwoFactorAuthentication')
            span.fp-text.-color-danger(data-test='invalid-two-factor-authentication') {{ t('loginRedirect.invalidTwoFactorAuthenticationCode') }}
          .fp-vertical-spacing--line.-justify-center
            .fp-horizontal-spacing.-s-sm(@click='newTwoFactorAuthentication' data-test='new-two-factor-authentication')
              .fp-horizontal-spacing--column
                span.fp-text.-text-sm {{ t('loginRedirect.notReceived') }}
              .fp-horizontal-spacing--column
                span.fp-text.-text-sm
                  a.fp-link.-secondary {{ t('loginRedirect.resend') }}
              .fp-horizontal-spacing--column(v-if='isAskingNewTwoFactorAuthentication')
                +fp-icon('loader', {color: 'shade-400', size: '18', spin: true})
          .fp-vertical-spacing--line.-justify-center
            FairplayerButtonVue(icon='check' :text='t(\'loginRedirect.validate\')' data-test='send-two-factor-authentication' :loading='isSendingTwoFactorAuthentication' @click='sendTwoFactorAuthentication')
      template(v-slot:footer)
        LogoutVue
  .fp-layout--toaster
    ToastsListVue
