.fp-vertical-spacing(data-test='kyc-summary')
  .fp-vertical-spacing--line(v-if='kycIncomplete' data-test='kyc-summary-card')
    .fp-card.-highlighted
      .fp-vertical-spacing
        .fp-vertical-spacing--line
          h2.fp-title {{ t(`kycSummary.card.title.${computedKycInformation.cardTitle.textKey}`) }}
        .fp-vertical-spacing--line
          span.fp-text.-paragraph(data-test='kyc-summary-card-description') {{ t(`kycSummary.card.description.${computedKycInformation.descriptionTextKey}`) }}
        .fp-vertical-spacing--line(v-if=`computedKycInformation.shouldDisplayKycValidation`)
          KycValidationVue(:fan='fan')
        .fp-vertical-spacing--line.-justify-center(v-if=`computedKycInformation.redirectButton.shouldDisplay`)
          FairplayerButtonVue(icon='log-in' data-test='go-to-kyc' :text='t(`kycSummary.card.kycButton.${computedKycInformation.redirectButton.textKey}`)' @click='goToKyc')
