import { KriptownCardValidity } from '@/fairplayer/secondary/fan/card/KriptownCardValidity';
import { Card } from '@/fairplayer/domain/fan/card/Card';
import { CardType } from '@/fairplayer/domain/fan/card/CardType';
import { CardId } from '@/fairplayer/domain/fan/card/CardId';
import { CardExpirationDate } from '@/fairplayer/domain/fan/card/CardExpirationDate';

export interface KriptownCard {
  id: CardId;
  cardType: CardType;
  expirationDate: number;
  alias: string;
  validity: KriptownCardValidity;
}

const VALID_VALIDITY_STATUS = ['VALID', 'UNKNOWN'];

export const toCard = (card: KriptownCard): Card => ({
  id: card.id,
  cardType: card.cardType,
  expirationDate: new CardExpirationDate(card.expirationDate),
  alias: card.alias,
  isValid: VALID_VALIDITY_STATUS.includes(card.validity),
});
