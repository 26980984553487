import { defineComponent, inject, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { formatRelativeTime } from '@/common/primary/relative-time/RelativeTimeFormatter';
import { fanLanguageRepositoryKey } from '@/common/domain/FanLanguageRepository';

export default defineComponent({
  name: 'RelativeTime',

  props: {
    date: {
      type: Date as PropType<Date>,
      required: true,
    },
    sm: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const fanLanguageRepository = inject(fanLanguageRepositoryKey)!;

    const currentLanguage = fanLanguageRepository.getCurrentLanguage();

    const relativeTime = formatRelativeTime(props.date, currentLanguage, t);

    return {
      relativeTime,
    };
  },
});
