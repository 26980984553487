import { inject, onMounted } from 'vue';
import { manifestHandlerKey } from '@/fairplayer/primary/ManifestHandler';

export default {
  name: 'App',

  setup() {
    const manifestHandler = inject(manifestHandlerKey)!;

    onMounted(() => manifestHandler.addDomContentListener());
  },
};
