import { Authentication } from '@/common/domain/auth/Authentication';
import { Optional } from '@/common/domain/Optional';
import { FanIdentity } from '@/common/domain/auth/FanIdentity';
import { AuthenticationProvider } from '@/common/domain/auth/AuthenticationProvider';
import { ClubWeb3Model } from '@/common/domain/club/ClubWeb3Model';
import { Credential } from '@/common/domain/auth/Credential';
import { AuthState } from '@/common/domain/auth/AuthState';

export class FairplayerAuthentication implements Authentication {
  private currentWeb3Model: Optional<ClubWeb3Model>;
  private authenticationProviders: Map<ClubWeb3Model, AuthenticationProvider | undefined> = new Map<
    ClubWeb3Model,
    AuthenticationProvider
  >();

  public constructor(kriptownAuth0Provider: AuthenticationProvider, fairplayerAuth0Provider: AuthenticationProvider) {
    this.authenticationProviders.set(ClubWeb3Model.KRIPTOWN, kriptownAuth0Provider);
    this.authenticationProviders.set(ClubWeb3Model.NONE, kriptownAuth0Provider);
    this.authenticationProviders.set(ClubWeb3Model.OFF_CHAIN, fairplayerAuth0Provider);
    this.currentWeb3Model = Optional.empty();
  }

  setCurrentWeb3Model(web3Model: ClubWeb3Model): void {
    this.currentWeb3Model = Optional.of(web3Model);
  }

  async login(kycNeeded?: boolean): Promise<void> {
    return this.authenticationProviders.get(this.currentWeb3Model.orElseThrow(this.noCurrentWeb3ModelError()))!.login(kycNeeded);
  }

  async loginWithCredential(credential: Credential, kycNeeded?: boolean): Promise<AuthState> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.loginWithCredential(credential, kycNeeded);
  }

  async googleLogin(kycNeeded?: boolean): Promise<void> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.googleLogin(kycNeeded);
  }

  async signUp(credential: Credential): Promise<void> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.signUp(credential);
  }

  async confirmEmail(confirmationCode: string, email: string): Promise<void> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.confirmEmail(confirmationCode, email);
  }

  async resendConfirmationCode(email: string): Promise<void> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.resendConfirmationCode(email);
  }

  async logout(redirect: boolean): Promise<void> {
    return this.authenticationProviders.get(this.currentWeb3Model.orElseThrow(this.noCurrentWeb3ModelError()))!.logout(redirect);
  }

  async handleRedirectCallback(clubWeb3Model: ClubWeb3Model): Promise<void> {
    return this.authenticationProviders.get(clubWeb3Model)?.handleRedirectCallback();
  }

  async askResetPassword(email: string): Promise<void> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.askResetPassword(email);
  }

  async confirmResetPassword(credential: Credential, confirmationCode: string): Promise<void> {
    return this.authenticationProviders.get(ClubWeb3Model.OFF_CHAIN)!.confirmResetPassword(credential, confirmationCode);
  }

  async authenticatedFan(): Promise<FanIdentity> {
    return this.authenticationProviders.get(this.currentWeb3Model.orElseThrow(this.noCurrentWeb3ModelError()))!.authenticatedFan();
  }
  async isAuthenticated(): Promise<boolean> {
    return this.currentWeb3Model
      .map(web3Model => this.authenticationProviders.get(web3Model)!.isAuthenticated())
      .orElse(Promise.resolve(false));
  }

  async jwtToken(): Promise<Optional<string>> {
    return this.currentWeb3Model
      .map(web3Model => this.authenticationProviders.get(web3Model)!.jwtToken())
      .orElse(Promise.resolve(Optional.empty()));
  }

  private noCurrentWeb3ModelError() {
    return () => new Error('Should have a current web3 model');
  }
}
