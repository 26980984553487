import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { orderRepositoryKey } from '@/fairplayer/domain/fan/order/OrderRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { OrderListVue } from '@/fairplayer/primary/order-list';
import { fromOrder, OrderUi } from '@/fairplayer/primary/order-list/Order.ui';
import { PageVue } from '@/fairplayer/primary/page';
import { KycSummaryVue } from '@/fairplayer/primary/profile/kyc-summary';
import { Loader } from '@/loader/primary/Loader';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LogoutVue } from '@/common/primary/auth/logout';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default {
  name: 'Profile',

  components: {
    ClubLogoVue,
    FairplayerButtonVue,
    KycSummaryVue,
    OrderListVue,
    PageVue,
    LogoutVue,
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const orderRepository = inject(orderRepositoryKey)!;

    const club = clubRepository.getCurrentClub();
    const showRedirectToClubSelection = fromClub(club).isMarketplace;
    const fan = ref(Loader.loading<Fan>());
    const orderList = ref(Loader.loading<OrderUi[]>());

    const kycValidated = computed(() => !fan.value.isLoading() && fan.value.value().kycStatus === KycStatus.KYC_VALIDATED);

    onMounted(async () => {
      await Promise.all([retrieveFan(), retrieveOrders()]);
    });

    const retrieveFan = async () => {
      fan.value.loaded(await fanRepository.getForClub(club));
    };

    const retrieveOrders = async () => {
      orderList.value.loaded(await orderRepository.listFor(clubRepository.getCurrentSlug()).then(orders => orders.map(fromOrder)));
    };

    return {
      fan,
      kycValidated,
      orderList,
      showRedirectToClubSelection,
      t,
    };
  },
};
