import { Tokens } from '@/common/domain/token/Tokens';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { CardId } from '@/fairplayer/domain/fan/card/CardId';
import { CardSecurityCode } from '@/fairplayer/domain/fan/card/CardSecurityCode';
import { CardTransactionRedirection } from '@/fairplayer/domain/fan/card/CardTransactionRedirection';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { InjectionKey } from 'vue';

export const walletRepositoryKey: InjectionKey<WalletRepository> = Symbol();

export interface WalletRepository {
  getForClub(clubSlug: ClubSlug): Promise<Wallet>;
  creditWalletByCard(cardId: CardId, tokens: Tokens, securityCode: CardSecurityCode): Promise<CardTransactionRedirection>;
  purchaseClubCoins(clubSlug: ClubSlug, tokens: Tokens): Promise<void>;
  computeSuccessFrontendRedirectionUrl(tokens: Tokens): string;
}
