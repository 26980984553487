import { KycAnswer } from '@/fairplayer/domain/kyc/KycAnswer';

export interface KycAnswerUi {
  id: number;
  slug: string;
}

export const fromKycAnswer = (kycAnswer: KycAnswer): KycAnswerUi => ({
  id: kycAnswer.id,
  slug: kycAnswer.slug,
});

export const toKycAnswer = (kycAnswerUi: KycAnswerUi): KycAnswer => ({
  id: kycAnswerUi.id,
  slug: kycAnswerUi.slug,
});
