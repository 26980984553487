import { Optional } from '@/common/domain/Optional';

export enum IdDocumentErrorType {
  UNREADABLE = 'UNREADABLE',
  EXPIRED = 'EXPIRED',
  MESSAGE = 'MESSAGE',
  UNKNOWN = 'UNKNOWN',
}

export interface IdDocumentError {
  type: IdDocumentErrorType;
  message: Optional<string>;
}
