include ../../../../../common/primary/icon/icon.mixin.pug

form.fp-vertical-spacing(autocomplete='on' @cancel.prevent='cancelCreate' @submit.prevent='create' data-test='add-card-form')
  .fp-bank-card-input
    .fp-bank-card-input--header
      h3.fp-title {{ t('wallet.addCardForm.title') }}
      +fp-icon('credit-card', {size: '18'})
    .fp-bank-card-input--form
      .fp-bank-card-input--form-line
        input.fp-input-text(name='cc-number' v-model='cardToCreate.cardNumber' :placeholder=`t('wallet.addCardForm.cardNumber')` data-test='card-number' required)
      .fp-bank-card-input--form-line
        input.fp-input-text(name='cc-exp' v-model='cardToCreate.expirationDate' :placeholder=`t('wallet.addCardForm.expirationDate')` data-test='expiration-date' required)
        input.fp-input-text(name='cc-csc' pattern='\\d{3,3}' v-model='cardToCreate.securityCode' :placeholder=`t('wallet.addCardForm.securityCode')` data-test='security-code' required)
      .fp-bank-card-input--form-line(v-if='errorKey' data-test='error-message')
        .fp-alert.-danger
          +fp-icon('close-circle', {size: '18', color:'warning'})
          span.fp-text {{ t(`${errorKey}`) }}
  .fp-vertical-spacing--line
    .fp-horizontal-spacing.-equivalent
      .fp-horizontal-spacing--column
        FairplayerButtonVue(icon='close' expand secondary :text='t(\'wallet.addCardForm.cancel\')' data-test='cancel-create-card' @click='cancelCreate')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(type='submit' data-test='create-card' expand icon='credit-card-plus' :loading='isCreatingCard' :text='t("wallet.addCardForm.createCard")')
