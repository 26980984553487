include ../../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-sm
  .fp-vertical-spacing--line
    h1.fp-title(data-test='exclusive-name') {{ exclusive.name }}
  template(v-if='isFoundation')
    .fp-vertical-spacing--line
      .fp-alert.-info
        +fp-icon('info', {color: 'info', size: '18'})
        span.fp-text.-paragraph(v-if='exclusive.auction' data-test='exclusive-auction') {{ t('marketplace.exclusiveDetail.auction.participate') }}
        template(v-if='!exclusive.auction')
          span.fp-text.-paragraph(v-if='exclusive.category === \'COLLECTIBLE\'' data-test='collectible-one-time-donation') {{ t('marketplace.exclusiveDetail.oneTimeDonationCollectible') }}
          span.fp-text.-paragraph(v-else data-test='experience-one-time-donation') {{ t('marketplace.exclusiveDetail.oneTimeDonationExperience') }}
  .fp-vertical-spacing--line(v-if='!isFoundation')
    span.fp-tag(data-test='exclusive-category')
      i.fp-icon(:class='exclusive.categoryIcon')
      span.fp-tag--text {{ t(`marketplace.categories.${exclusive.category.toLowerCase()}`) }}
  .fp-vertical-spacing--line(v-if='exclusive.auction && exclusive.auction.bids && exclusive.auction.bids.length' data-test='current-price')
    .fp-vertical-spacing.-s-xs
      .fp-vertical-spacing--line
        h2.fp-title {{ t('marketplace.exclusiveDetail.auction.currentPrice') }}
      .fp-vertical-spacing--line
        .fp-card.-highlighted
          .fp-horizontal-spacing.-items-center.-s-sm
            .fp-horizontal-spacing--column
              .fp-image.-circled
                img(:src='exclusive.auction.bids[0].bidder.pictureUrl' alt='Picture')
            .fp-horizontal-spacing--column.-expand
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  span.fp-text.-text-sm.-text-semi-bold {{ exclusive.auction.bids[0].bidder.firstName }} {{ exclusive.auction.bids[0].bidder.lastName }}
                .fp-vertical-spacing--line
                  RelativeTimeVue(:date='exclusive.auction.bids[0].date' sm)
            .fp-horizontal-spacing--column
              span.fp-text.-text-semi-bold {{ exclusive.auction.bids[0].price.totalCost.text }}
  .fp-vertical-spacing--line.-hidden-up-md(v-if='!exclusive.auction' data-test='heading-price')
    ExclusivePriceVue(:pricing='exclusive.pricing' xl)
