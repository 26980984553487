import { useI18n } from 'vue-i18n';
import { computed, PropType, defineComponent } from 'vue';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { KycValidationVue } from '@/fairplayer/primary/profile/kyc-summary/kyc-validation';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { toKycSummaryDisplayUI } from '@/fairplayer/primary/profile/kyc-summary/KycSummary.ui';
import { FairplayerButtonVue } from '@/common/primary/button';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'KycSummary',
  components: {
    KycValidationVue,
    FairplayerButtonVue,
  },

  props: {
    fan: {
      type: Object as PropType<Fan>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const kycIncomplete = computed(() => props.fan.kycStatus !== KycStatus.KYC_VALIDATED);
    const computedKycInformation = computed(() => toKycSummaryDisplayUI(props.fan));

    const goToKyc = () => router.push({ name: 'kyc' });

    return {
      computedKycInformation,
      kycIncomplete,
      goToKyc,
      t,
    };
  },
});
