import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { themeStorageKey } from '@/fairplayer/domain/theme/ThemeStorage';

export default defineComponent({
  name: 'ThemeSelector',

  setup() {
    const { t } = useI18n();

    const themeStorage = inject(themeStorageKey)!;

    const setTheme = (theme: string) => {
      themeStorage.save(theme);
      themeStorage.set(theme);
    };

    return {
      setTheme,
      t,
    };
  },
});
