export enum CardType {
  CB_VISA_MASTERCARD = 'CB_VISA_MASTERCARD',
  DINERS = 'DINERS',
  MASTERPASS = 'MASTERPASS',
  AMEX = 'AMEX',
  MAESTRO = 'MAESTRO',
  P24 = 'P24',
  IDEAL = 'IDEAL',
  BCMC = 'BCMC',
  PAYLIB = 'PAYLIB',
}
