include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line
        h1.fp-title {{ t('wallet.title') }}
      template(v-if='!cards.isLoading()')
        .fp-vertical-spacing--line(v-if='!cards.value().length')
          span.fp-text(data-test='cards-list.empty') {{ t('wallet.emptyCards') }}
        .fp-vertical-spacing--line(v-for='(card, index) in cards.value()' :key='index')
          label.fp-listing-element(:data-test='`cards-list.${index}`')
            input.fp-radio(type='radio' name='selectedCard' :value='card' v-model='selectedCard')
            span.fp-text(:data-test='`cards-list.${index}.alias`') {{ card.alias }}
            span.fp-text.-text-light(v-if='!card.isValid' :data-test='`cards-list.${index}.state`')  - {{ t('wallet.invalidCard') }}
            .fp-listing-element--icon
              +fp-icon('credit-card', {size: '18'})
        .fp-vertical-spacing--line(v-if='!addCardFormVisible')
          FairplayerButtonVue(:disabled='!selectedCard' expand :text='t(\'wallet.continueToCredit\')' @click='continueToCredit' data-test='continue-to-credit')
        .fp-vertical-spacing--line.-align-center(v-if='!addCardFormVisible')
          a.fp-link(data-test='new-card' @click='showAddCardForm')
            span.fp-button--icon
              +fp-icon('plus', {size: '16'})
            | {{ t('wallet.addCard') }}
        .fp-vertical-spacing--line(v-if='addCardFormVisible')
          AddCardFormVue(@card-created='addCreatedCard' @cancel-create='hideAddCardForm')
