import { BackendKycAnswer, toKycAnswers } from '@/fairplayer/secondary/kyc/BackendKycAnswer';
import { KycQuestion } from '@/fairplayer/domain/kyc/KycQuestion';

export interface BackendKycQuestion {
  slug: string;
  answers: BackendKycAnswer[];
}

export const toKycQuestion = (backendKycQuestion: BackendKycQuestion): KycQuestion => ({
  slug: backendKycQuestion.slug,
  answers: toKycAnswers(backendKycQuestion.answers),
});
