import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { InjectionKey } from 'vue';
import { Message } from '@/fairplayer/domain/message/Message';
import { MessageToCreate } from '@/fairplayer/domain/message/MessageToCreate';
import { InterlocutorMessages } from '@/fairplayer/domain/message/InterlocutorMessages';
import { FanId } from '@/common/domain/FanId';

export const messageRepositoryKey: InjectionKey<MessageRepository> = Symbol();

export interface MessageRepository {
  getForClub(clubSlug: ClubSlug): Promise<InterlocutorMessages[]>;
  sendForClub(clubSlug: ClubSlug, message: MessageToCreate): Promise<Message>;
  readConversation(clubSlug: ClubSlug, senderId: FanId): void;
}
