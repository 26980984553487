import { useI18n } from 'vue-i18n';
import { computed, inject, ref } from 'vue';
import { FairplayerButtonVue } from '@/common/primary/button';
import { modalBusKey } from '@/common/domain/modal/ModalBus';

export default {
  name: 'CreditWalletConfirmModal',

  components: { FairplayerButtonVue },

  setup() {
    const { t } = useI18n();
    const modalBus = inject(modalBusKey)!;
    const url = `${import.meta.env.VITE_STATIC_FILES_URL}/tos/Fairplayer-tokens-eula.pdf`;

    const checkAssetsSalesInput = ref(false);
    const checkUseTokensInput = ref(false);
    const checkSfcFairplayerInput = ref(false);

    const isConfirmDisabled = computed(() => !checkAssetsSalesInput.value || !checkUseTokensInput.value || !checkSfcFairplayerInput.value);

    const cancel = () => {
      modalBus.close();
    };

    const confirm = () => {
      modalBus.emit('confirm');
      modalBus.close();
    };

    return {
      cancel,
      confirm,
      checkAssetsSalesInput,
      checkUseTokensInput,
      checkSfcFairplayerInput,
      isConfirmDisabled,
      t,
      url,
    };
  },
};
