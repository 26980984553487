import { GiveawayQuestion } from '@/fairplayer/domain/club/GiveawayQuestion';
import { fromGiveawayAnswer, GiveawayAnswerUi } from '@/fairplayer/primary/club/GiveawayAnswer.ui';
import { renderMarkdown } from '@/common/primary/MarkdownRenderer';

export interface GiveawayQuestionUi {
  id: string;
  title: string;
  preTitle?: string;
  answers: GiveawayAnswerUi[];
  required: boolean;
}

export const fromGiveawayQuestion = (giveawayQuestion: GiveawayQuestion): GiveawayQuestionUi => ({
  id: giveawayQuestion.id,
  title: giveawayQuestion.title,
  preTitle: giveawayQuestion.preTitle.map(renderMarkdown).orElseUndefined(),
  answers: giveawayQuestion.answers.map(fromGiveawayAnswer),
  required: giveawayQuestion.required,
});
