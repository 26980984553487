import { ApolloClient, ApolloQueryResult, gql, OperationVariables, WatchQueryOptions } from '@apollo/client/core';
import { FetchResult } from '@apollo/client/link/core';
import { QueryOptions } from '@apollo/client/core/watchQueryOptions';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { InjectionKey } from 'vue';
import { Observable } from '@apollo/client';

const buildQueryOptions = (query: string, options: { variables?: OperationVariables; cache?: boolean }) => {
  const queryOptions: QueryOptions = {
    query: gql`
      ${query}
    `,
  };
  if (options.variables) {
    queryOptions.variables = options.variables;
  }
  if (options.cache) {
    queryOptions.fetchPolicy = 'cache-first';
  }
  return queryOptions;
};

const buildWatchQueryOptions = (query: string, options: { variables?: OperationVariables; cache?: boolean; pollInterval?: number }) => {
  const watchQueryOptions: WatchQueryOptions = {
    query: gql`
      ${query}
    `,
  };
  if (options.variables) {
    watchQueryOptions.variables = options.variables;
  }
  if (options.cache) {
    watchQueryOptions.fetchPolicy = 'cache-first';
  }
  if (options.pollInterval) {
    watchQueryOptions.pollInterval = options.pollInterval;
  }
  return watchQueryOptions;
};

export const kriptownCallerKey: InjectionKey<KriptownCaller> = Symbol();

export class KriptownCaller {
  constructor(
    private apolloClient: ApolloClient<any>,
    private alertBus: AlertBus
  ) {}

  async query<T>(query: string, options: { variables?: OperationVariables; cache?: boolean } = {}): Promise<ApolloQueryResult<T>> {
    return this.apolloClient.query(buildQueryOptions(query, options)).catch(error => this.throwError(error));
  }

  async mutate<T>(mutation: string, input?: Record<string, any>): Promise<FetchResult<T>> {
    return this.apolloClient
      .mutate({
        mutation: gql`
          ${mutation}
        `,
        variables: {
          input,
        },
      })
      .catch(error => this.throwError(error));
  }

  watchQuery<T>(
    query: string,
    options: { variables?: OperationVariables; cache?: boolean; pollInterval?: number } = {}
  ): Observable<ApolloQueryResult<T>> {
    return this.apolloClient.watchQuery(buildWatchQueryOptions(query, options));
  }

  private throwError(error: any): never {
    this.alertBus.alert({ message: 'errors.backend.unknown', type: 'danger' });
    throw new Error(error);
  }
}
