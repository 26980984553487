import { extractTokens } from '@/fairplayer/primary/wallet/payment-processing/extractTokens';
import { PaymentFailureReason } from '@/fairplayer/primary/wallet/payment-processing/PaymentFailureReason';
import { Subscription } from '@/common/domain/Observable';
import { Optional } from '@/common/domain/Optional';
import { Fiat } from '@/common/domain/token/Fiat';
import { Tokens } from '@/common/domain/token/Tokens';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { PageVue } from '@/fairplayer/primary/page';
import { inject, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';

export default {
  name: 'PaymentProcessing',

  components: { PageVue },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const walletRepository = inject(walletRepositoryKey)!;
    const subscription = ref<Subscription>();
    const purchaseTimeout = import.meta.env.VITE_PURCHASE_TIMEOUT_IN_SECONDS * 1000;
    const timeoutId = ref<NodeJS.Timeout>();

    const createPaymentConfirmedQuery = (wallet: Wallet, tokens: Tokens) => {
      const initialCoinsAmount = wallet.balance.coins.amount;
      const availableCoinsAmount = initialCoinsAmount + tokens.coins.amount;
      return {
        initialCoinsAmount,
        ...route.query,
        availableCoinsAmount,
      };
    };

    const goToPaymentFailed = async (reason: PaymentFailureReason) =>
      await router.push({
        name: 'paymentFailed',
        query: { reason },
      });

    const onFiatProcessed = async (fiatNotProcessed: Optional<Fiat>, query: any) => {
      if (fiatNotProcessed.isEmpty()) {
        await router.push({ name: 'paymentConfirmed', query });
      }
    };

    const purchaseClubCoins = async (tokens: Tokens) => {
      await Promise.all([
        walletRepository.getForClub(clubRepository.getCurrentSlug()),
        walletRepository.purchaseClubCoins(clubRepository.getCurrentSlug(), tokens),
      ])
        .then(async ([wallet]) => {
          timeoutId.value = setTimeout(async () => await goToPaymentFailed(PaymentFailureReason.TOKEN_BUY), purchaseTimeout);
          const query = createPaymentConfirmedQuery(wallet, tokens);

          const observable = await fanRepository.observableFiatNotProcessed();
          subscription.value = observable.subscribe(async fiatNotProcessed => await onFiatProcessed(fiatNotProcessed, query));
        })
        .catch(async () => {
          await goToPaymentFailed(PaymentFailureReason.TOKEN_BUY);
        });
    };

    onMounted(async () => {
      const paymentSucceed = Boolean(route.query.success && route.query.success === '1');

      if (paymentSucceed) {
        await purchaseClubCoins(extractTokens(route.query));
      } else {
        await goToPaymentFailed(PaymentFailureReason.CREDIT_CARD);
      }
    });

    onUnmounted(() => {
      subscription.value?.unsubscribe();
      clearTimeout(timeoutId.value);
    });

    return {
      t,
    };
  },
};
