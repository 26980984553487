import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fromFan, KycValidationUi } from '@/fairplayer/primary/profile/kyc-summary/kyc-validation/KycValidation.ui';
import { defineComponent, PropType, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'KycValidation',
  props: {
    fan: {
      type: Object as PropType<Fan>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const kycValidationUi = ref<KycValidationUi>(fromFan(toRef(props, 'fan').value));

    return {
      kycValidationUi,
      t,
    };
  },
});
