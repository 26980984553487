import { inject, ref } from 'vue';
import { cardRepositoryKey } from '@/fairplayer/domain/fan/card/CardRepository';
import { useI18n } from 'vue-i18n';
import { fromCard } from '@/fairplayer/primary/wallet/cards-list/Card.ui';
import { CardToCreateUi, emptyCardToCreateUi, toCardToCreate } from '@/fairplayer/primary/wallet/cards-list/add-card-form/CardToCreate.ui';
import { FairplayerButtonVue } from '@/common/primary/button';
import { KnownError } from '@/common/domain/KnownError';

export default {
  name: 'AddCardForm',

  components: { FairplayerButtonVue },

  setup(props: any, { emit }: any) {
    const { t } = useI18n();
    const cardRepository = inject(cardRepositoryKey)!;

    const cardToCreate = ref<CardToCreateUi>(emptyCardToCreateUi());
    const isCreatingCard = ref(false);
    const errorKey = ref('');

    const create = async () => {
      isCreatingCard.value = true;
      try {
        await cardRepository.create(toCardToCreate(cardToCreate.value)).then(createdCard => emit('card-created', fromCard(createdCard)));
      } catch (error: any) {
        if (error instanceof KnownError) {
          errorKey.value = error.message;
        } else {
          errorKey.value = 'wallet.addCardForm.unknownError';
        }
        isCreatingCard.value = false;
      }
    };

    const cancelCreate = async () => {
      emit('cancel-create');
    };

    return {
      create,
      cardToCreate,
      cancelCreate,
      isCreatingCard,
      errorKey,
      t,
    };
  },
};
