import { LocationQuery } from 'vue-router';
import { fromSymbol } from '@/common/domain/token/Currency';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';

export const extractTokens = (query: LocationQuery) => {
  const coinsAmount = +query.coinsAmount!;
  const coinsTicker = query.coinsTicker!.toString();
  const fiatValue = +query.fiatValue!;
  const fiatCurrencySymbol = query.fiatCurrency!.toString();
  const fiatCurrency = fromSymbol(fiatCurrencySymbol);

  return Tokens.of(new Coins(coinsAmount, coinsTicker)).withTotalCost({
    value: fiatValue,
    currency: fiatCurrency,
  });
};
