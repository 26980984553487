import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { Exclusive } from '@/fairplayer/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/fairplayer/domain/exclusive/ExclusiveCategory';
import { LocalDate } from '@/common/domain/LocalDate';
import { Club } from '@/fairplayer/domain/club/Club';
import { fromMedia, MediaUi } from '@/fairplayer/primary/marketplace/exclusives-list/Media.ui';
import { renderMarkdown } from '@/common/primary/MarkdownRenderer';
import { AuctionUi, fromAuction } from '@/fairplayer/primary/marketplace/Auction.ui';
import { FanId } from '@/common/domain/FanId';

export interface ExclusiveUi {
  id: string;
  name: string;
  formattedDescription: string;
  slug: string;
  imageUrl: string;
  medias: MediaUi[];
  pricing: TokensUi;
  visible: boolean;
  stock: number;
  isOutOfStock: boolean;
  category: ExclusiveCategory;
  categoryIcon: string;
  isUpcoming: boolean;
  upcomingDate?: LocalDate;
  auction?: AuctionUi;
}

const toCategoryIcon = (category: ExclusiveCategory): string => {
  switch (category) {
    case ExclusiveCategory.ACCESS:
      return 'fp-glyph-lock-open';
    case ExclusiveCategory.MOMENT:
      return 'fp-glyph-handshake';
    case ExclusiveCategory.COLLECTIBLE:
      return 'fp-glyph-award';
  }
};

const computeStock = (stock: number, clubState: ClubState) => {
  if (clubState === ClubState.STAND_BY) {
    return 0;
  }

  return stock;
};

const computeVisible = (visible: boolean, clubState: ClubState) => {
  if (clubState === ClubState.ARCHIVED) {
    return false;
  }

  return visible;
};

export const fromExclusive = (exclusive: Exclusive, club: Club, fanId?: FanId): ExclusiveUi => {
  const stock = computeStock(exclusive.stock, club.state);
  const visible = computeVisible(exclusive.visible, club.state);

  return {
    id: exclusive.id,
    name: exclusive.name,
    formattedDescription: renderMarkdown(exclusive.description),
    imageUrl: exclusive.imageUrl,
    medias: exclusive.medias.map(fromMedia),
    pricing: fromTokens(exclusive.pricing),
    visible,
    stock,
    isOutOfStock: stock === 0,
    category: exclusive.category,
    categoryIcon: toCategoryIcon(exclusive.category),
    slug: exclusive.slug,
    isUpcoming: club.state === ClubState.UPCOMING,
    upcomingDate: club.upcomingDate.orElseUndefined(),
    auction: exclusive.auction.map(auction => fromAuction(auction, fanId)).orElseUndefined(),
  };
};
