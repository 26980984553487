import { authenticationKey } from '@/common/domain/auth/Authentication';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { PageVue } from '@/fairplayer/primary/page';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';
import { inject, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { LogoutVue } from '@/common/primary/auth/logout';
import { loggerKey } from '@/common/domain/Logger';
import { loginContextRepositoryKey } from '@/common/domain/login/LoginContextRepository';

const APPLICATION_BASE_URL = window.location.origin;

export default {
  name: 'LoginRedirectCallback',

  components: { PageVue, ToastsListVue, LogoutVue },

  setup() {
    const { t } = useI18n();
    const authentication = inject(authenticationKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const loginContextRepository = inject(loginContextRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const router = useRouter();
    const currentClubSlug = clubRepository.getCurrentSlug();
    let fan: Fan;

    const getRedirectUrl = (fan: Fan): string => {
      const loginContext = loginContextRepository.retrieveLoginContext();
      if (fan.kycStatus !== KycStatus.KYC_VALIDATED && loginContext.kycNeeded) {
        return `${APPLICATION_BASE_URL}/clubs/${currentClubSlug}/profile`;
      }
      return loginContext.redirectUrl || APPLICATION_BASE_URL;
    };

    onMounted(async () => {
      const club = await clubRepository.retrieveClub();
      authentication.setCurrentWeb3Model(club.web3Model);
      try {
        await authentication.handleRedirectCallback(club.web3Model);
      } catch (error: any) {
        logger.error('Failed to handle redirect callback', error);

        return router.push({ name: 'homepage' });
      }
      const fanIdentity = await authentication.authenticatedFan();

      await fanRepository.registerFan({ clubSlug: club.slug, pictureUrl: fanIdentity.pictureUrl });
      fan = await fanRepository.getForClub(club);

      if (fan.twoFactorValidated) {
        await pageRedirecter.navigateTo(getRedirectUrl(fan));
      } else {
        await router.push({ name: 'twoFactorAuthentication', query: { redirect: getRedirectUrl(fan) }, replace: true });
      }
    });

    return {
      t,
    };
  },
};
