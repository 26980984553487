import { RouteLocationRaw } from 'vue-router';
import { TokensUi } from '@/common/primary/token/Tokens.ui';
import { ComposerDateTimeFormatting, ComposerTranslation } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { ProgramUi } from '@/fairplayer/primary/programs/Program.ui';

export interface PresentationCardItem {
  id: string;
  imageUrl: string;
  title: string;
  route: RouteLocationRaw;
  pricing?: TokensUi;
  maskText?: string;
}

const toExclusiveMaskText = (exclusive: ExclusiveUi, t: ComposerTranslation, d: ComposerDateTimeFormatting): string | undefined => {
  if (exclusive.isUpcoming && exclusive.upcomingDate) {
    return t('marketplace.upcomingExclusiveWithDate', { date: d(exclusive.upcomingDate.toDate(), { month: 'long', day: 'numeric' }) });
  }
  if (exclusive.isUpcoming && !exclusive.upcomingDate) {
    return t('marketplace.upcomingExclusive');
  }
  if (!exclusive.visible) {
    return t('marketplace.hiddenExclusive');
  }
  if (exclusive.isOutOfStock) {
    return t('marketplace.outOfStock');
  }
  return undefined;
};

export const fromExclusiveUi = (exclusive: ExclusiveUi, t: ComposerTranslation, d: ComposerDateTimeFormatting): PresentationCardItem => ({
  id: exclusive.slug,
  imageUrl: exclusive.imageUrl,
  title: exclusive.name,
  route: { name: 'exclusiveDetail', params: { exclusiveSlug: exclusive.slug } },
  pricing: exclusive.pricing,
  maskText: toExclusiveMaskText(exclusive, t, d),
});

export const fromProgramUi = (program: ProgramUi): PresentationCardItem => ({
  id: program.slug,
  imageUrl: program.imageUrl,
  title: program.name,
  route: { name: 'programPage', params: { programSlug: program.slug } },
});
