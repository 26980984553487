import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendFiat } from '@/common/secondary/token/BackendFiat';
import { BackendFan } from '@/fairplayer/secondary/fan/BackendFan';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { FanPersonalInfo } from '@/fairplayer/domain/fan/FanPersonalInfo';
import { fromFanPersonalInfo } from '@/fairplayer/secondary/fan/BackendFanPersonalInfo';
import { FanToCreate } from '@/fairplayer/domain/fan/FanToCreate';
import { fromFanToCreate } from '@/fairplayer/secondary/fan/BackendFanToCreate';

export class BackendFanRepository {
  private validatedFan: BackendFan | undefined;

  constructor(private backendCaller: BackendCaller) {}

  async registerFan(fanToCreate: FanToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${fanToCreate.clubSlug}/fans`, fromFanToCreate(fanToCreate));
  }

  async getFan(clubSlug: ClubSlug, forceRefresh = false): Promise<BackendFan> {
    if (this.validatedFan && !forceRefresh) {
      return this.validatedFan;
    }
    const fan = (await this.backendCaller.get<BackendFan>(`api/clubs/${clubSlug}/fans/me`)).data;

    if (fan.kycInfo.kycRegular) {
      this.validatedFan = fan;
    }
    return fan;
  }

  async sendPersonalFanInfo(fanPersonalInfo: FanPersonalInfo): Promise<void> {
    await this.backendCaller.put<void>('api/fans/me/personal-info', fromFanPersonalInfo(fanPersonalInfo));
  }

  getBalance(): Promise<BackendFiat> {
    return this.backendCaller.get<BackendFiat>('api/fans/me/balance').then(backendFiat => backendFiat.data);
  }

  async acceptTos(clubSlug: ClubSlug): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/fans/me/tos-acceptances`);
  }
}
