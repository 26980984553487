import { fromMessage, MessageUi } from '@/fairplayer/primary/Message.ui';
import { InterlocutorMessages } from '@/fairplayer/domain/message/InterlocutorMessages';
import { FanId } from '@/common/domain/FanId';
import { MemberUi } from '@/fairplayer/primary/member/Member.ui';

export interface InterlocutorMessagesUi {
  interlocutorId: string;
  interlocutorPictureUrl: string;
  interlocutorFirstName: string;
  interlocutorLastName: string;
  messages: MessageUi[];
  numberOfUnreadMessages: number;
}

export const fromInterlocutorMessages = (
  senderId: FanId,
  interlocutor: MemberUi,
  interlocutorMessages?: InterlocutorMessages
): InterlocutorMessagesUi => ({
  interlocutorId: interlocutor.id,
  interlocutorPictureUrl: interlocutor.pictureUrl,
  interlocutorFirstName: interlocutor.firstName,
  interlocutorLastName: interlocutor.lastName,
  messages: interlocutorMessages?.messages.map(message => fromMessage(message, senderId === message.senderId)) ?? [],
  numberOfUnreadMessages: interlocutorMessages?.numberOfUnreadMessages ?? 0,
});
