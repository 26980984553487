import { InjectionKey } from 'vue';

const MOBILE_BREAKPOINT = 768;

export const mobileWindowCheckerKey: InjectionKey<MobileWindowChecker> = Symbol();
export class MobileWindowChecker {
  constructor(private globalWindow: Window) {}
  isMobile(): boolean {
    return this.globalWindow.innerWidth <= MOBILE_BREAKPOINT;
  }
}
