import { ExclusiveUi, fromExclusive } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { computed, defineComponent, inject, onMounted, PropType, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { exclusiveRepositoryKey } from '@/fairplayer/domain/exclusive/ExclusiveRepository';
import { windowScrollerKey } from '@/common/primary/WindowScroller';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { fromExclusiveUi } from '@/common/primary/presentation-card/PresentationCardItem.ui';
import { ExclusivesCarouselVue } from '@/fairplayer/primary/exclusive-carousel';

export default defineComponent({
  name: 'RecommendedExclusives',

  components: { ExclusivesCarouselVue },

  props: {
    currentExclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup(props) {
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const exclusiveRepository = inject(exclusiveRepositoryKey)!;
    const windowScroller = inject(windowScrollerKey)!;

    const club = clubRepository.getCurrentClub();
    const recommendedExclusives = ref<ExclusiveUi[]>([]);
    const { t, d } = useI18n();

    watch(
      () => [props.currentExclusive],
      () => loadRecommendedExclusives()
    );

    const carouselItems = computed(() => recommendedExclusives.value.map(exclusive => fromExclusiveUi(exclusive, t, d)));

    const loadRecommendedExclusives = async () => {
      return exclusiveRepository
        .listRecommended(club.slug, props.currentExclusive.slug, props.currentExclusive.category)
        .then(list => {
          windowScroller.scrollToTop();
          recommendedExclusives.value = list.map(exclusive => fromExclusive(exclusive, club));
        })
        .catch(error => logger.error('Failed to retrieve recommended exclusives', error));
    };

    onMounted(() => loadRecommendedExclusives());

    return { t, carouselItems };
  },
});
