import { defineComponent, inject, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExclusiveUi } from '@/fairplayer/primary/marketplace/Exclusive.ui';
import { useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';
import { GiveawayCardVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/giveaway-card';
import { ExclusiveDetailHeadingVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-detail-heading';
import { highlightedMediaBusKey } from '@/common/domain/highlighted-media/HighlightedMediaBus';
import { fromMedia } from '@/fairplayer/primary/marketplace/exclusives-list/Media.ui';
import { MediaType } from '@/common/domain/MediaType';
import { ExclusiveMediaVue } from '@/common/primary/exclusive-media';
import { ExclusiveAuctionVue } from '@/fairplayer/primary/marketplace/exclusive-page/exclusive-detail/exclusive-auction';
import { FairplayerVideoVue } from '@/common/primary/fairplayer-video';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default defineComponent({
  name: 'ExclusiveDetail',

  components: {
    FairplayerButtonVue,
    GiveawayCardVue,
    ExclusiveAuctionVue,
    ExclusiveDetailHeadingVue,
    ExclusiveMediaVue,
    FairplayerVideoVue,
  },

  props: {
    exclusive: {
      type: Object as PropType<ExclusiveUi>,
      required: true,
    },
  },

  setup(props) {
    const { d, t } = useI18n();
    const router = useRouter();
    const clubRepository = inject(clubRepositoryKey)!;
    const highlightedMediaBus = inject(highlightedMediaBusKey)!;

    const club = fromClub(clubRepository.getCurrentClub()!!);

    const selectedMedia = ref(0);

    const medias = [{ url: props.exclusive.imageUrl, type: MediaType.IMAGE }, ...props.exclusive.medias.map(fromMedia)];

    const goBack = async () => {
      if (club.isFoundation) {
        if (props.exclusive.auction === undefined) {
          await goToOneTimeDonation();
        } else {
          await goToClubHomepage();
        }
      } else {
        await goToExclusiveList();
      }
    };
    const goToExclusiveList = async () => await router.push({ name: 'marketplace' });
    const goToClubHomepage = async () => await router.push({ name: 'clubHomepage' });
    const goToOneTimeDonation = async () => await router.push({ name: 'oneTimeDonation' });

    const selectMedia = (index: number) => {
      selectedMedia.value = index;
    };

    const highlightMedia = () => {
      highlightedMediaBus.open({
        medias,
        activeIndex: selectedMedia.value,
      });
    };

    return {
      d,
      t,
      selectedMedia,
      medias,
      selectMedia,
      goBack,
      highlightMedia,
    };
  },
});
