import { defineAsyncComponent } from 'vue';
import { ComponentStep } from '@/common/domain/Step';

export const kycSteps: ComponentStep[] = [
  {
    component: defineAsyncComponent(() => import('../tos/form/TosForm.vue')),
    titleKey: 'tos.title',
    percentage: 0,
  },
  {
    component: defineAsyncComponent(() => import('../fan-personal-info/FanPersonalInfoForm.vue')),
    titleKey: 'fanPersonalInfo.title',
    percentage: 20,
  },
  {
    component: defineAsyncComponent(() => import('../due-diligence/DueDiligence.vue')),
    titleKey: 'dueDiligence.title',
    tooltipKey: 'dueDiligence.tooltip',
    percentage: 40,
  },
  {
    component: defineAsyncComponent(() => import('../pending-due-diligence/PendingDueDiligence.vue')),
    titleKey: 'pendingDueDiligence.title',
    percentage: 60,
  },
  {
    component: defineAsyncComponent(() => import('../membership-donation/MembershipDonation.vue')),
    titleKey: 'membershipDonation.title',
    percentage: 80,
  },
  {
    component: defineAsyncComponent(() => import('../membership-donation/payment-confirmed/MembershipDonationPaymentConfirmed.vue')),
    titleKey: 'membershipDonationPayment.title',
    percentage: 100,
  },
];
