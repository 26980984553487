include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-align-center
        +fp-icon('check-circle', {color: 'success', size: '50'})
      .fp-vertical-spacing--line.-align-center
        h2.fp-title {{ t('wallet.paymentConfirmed.thanks') }}
      .fp-vertical-spacing--line.-align-center
        .fp-text {{ t('wallet.paymentConfirmed.emailConfirmation') }}
      .fp-vertical-spacing--line
        .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-text.-text-xl.-text-semi-bold.-color-shade-900 {{ t('wallet.paymentConfirmed.yourTransaction') }}
      .fp-vertical-spacing--line
        .fp-horizontal-spacing
          .fp-horizontal-spacing--column.-expand
            span.fp-text {{ t('wallet.paymentConfirmed.initialBalance') }}
          .fp-horizontal-spacing--column
            span.fp-text.-text-semi-bold.-color-shade-900(data-test='payment-confirmed.initial-balance')  {{ initialBalance.coins.text }}
      .fp-vertical-spacing--line
        .fp-horizontal-spacing
          .fp-horizontal-spacing--column.-expand
            span.fp-text(data-test='payment-confirmed.total-cost') +{{ tokensUi.totalCost.text }}
          .fp-horizontal-spacing--column
            span.fp-text.-text-semi-bold.-color-shade-900(data-test='payment-confirmed.coins') +{{ tokensUi.coins.text }}
      .fp-vertical-spacing--line
        .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing
          .fp-horizontal-spacing--column.-expand
            span.fp-text {{ t('wallet.paymentConfirmed.availableBalance') }}
          .fp-horizontal-spacing--column
            span.fp-text.-text-semi-bold.-color-shade-900(data-test='payment-confirmed.available-balance') {{ availableBalance.coins.text }}
      .fp-vertical-spacing--line
        .fp-separator.-secondary
      .fp-vertical-spacing--line.-justify-center
        FairplayerButtonVue(icon='shopping-bag' :text='t(\'wallet.paymentConfirmed.goToMarketplace\')' data-test='payment-confirmed.button' @click='goToMarketplace')
