include ../../../../common/primary/icon/icon.mixin.pug

PageVue(data-test='members')
  template(v-slot:body v-if='membersList.isLoading()')
    .fp-vertical-spacing.-s-sm
      .fp-vertical-spacing--line(v-for='i in 10')
        .fp-placeholder
  template(v-slot:body v-if='!membersList.isLoading()')
    .fp-alert.-info(v-if='!membersList.value().length' data-test='alert')
      +fp-icon('info', {color: 'info', size: '18'})
      span.fp-text.-paragraph {{ t('members.alert.emptyMembers') }}
    .fp-vertical-spacing.-s-lg(v-else)
      .fp-vertical-spacing--line(v-if='hasConversations')
        InterlocutorsListVue(:interlocutors-messages='interlocutorsMessages.value()')
      .fp-vertical-spacing--line
        .fp-vertical-spacing
          .fp-vertical-spacing--line
            h2.fp-title {{ t('members.members') }}
          .fp-vertical-spacing--line
            .fp-responsive-grid.-cols4.-cols3-up-md.-cols2-up-sm
              .fp-responsive-grid--column(v-for='(member, index) in membersList.value()' :key='index')
                MemberCardVue(:member='member' :data-test='`member-${index}`')
