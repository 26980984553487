include ../../common/primary/icon/icon.mixin.pug

.fp-layout
  .fp-layout--body
    PageVue
      template(v-slot:body)
        .fp-vertical-spacing
          .fp-vertical-spacing--line.-justify-center
            h1.fp-title {{ t('loginRedirect.welcome') }}
          .fp-vertical-spacing--line.-justify-center
            +fp-icon('loader', {color: 'shade-400', size: '50', spin: true})
      template(v-slot:footer)
        LogoutVue
  .fp-layout--toaster
    ToastsListVue
