include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-align-center
        +fp-icon('loader', {color: 'shade-400', size: '50', spin: true})
      .fp-vertical-spacing--line.-align-center
        h2.fp-title {{ t('wallet.paymentProcessing.title') }}
      .fp-vertical-spacing--line.-align-center
        .fp-text {{ t('wallet.paymentProcessing.subtitle') }}
      .fp-vertical-spacing--line
        .fp-separator.-secondary
