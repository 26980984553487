import { BackendCaller } from '@/common/secondary/BackendCaller';
import { DonationRepository } from '@/fairplayer/domain/donation/DonationRepository';
import { CheckoutSession } from '@/fairplayer/domain/CheckoutSession';
import { BackendCheckoutSession, toCheckoutSession } from '@/fairplayer/secondary/BackendCheckoutSession';
import { DonationToCreate } from '@/fairplayer/domain/donation/DonationToCreate';
import { fromDonationToCreate } from '@/fairplayer/secondary/donation/BackendDonationToCreate';

export class BackendDonationRepository implements DonationRepository {
  constructor(private backendCaller: BackendCaller) {}

  async create(donationToCreate: DonationToCreate): Promise<CheckoutSession> {
    return this.backendCaller
      .post<BackendCheckoutSession>(`api/clubs/${donationToCreate.clubSlug}/donations`, fromDonationToCreate(donationToCreate))
      .then(checkoutSession => toCheckoutSession(checkoutSession.data));
  }
}
