import { KycAnswer } from '@/fairplayer/domain/kyc/KycAnswer';

export interface BackendKycAnswer {
  id: number;
  slug: string;
}

export const toKycAnswers = (backendKycAnswers: BackendKycAnswer[]) => {
  const answers = backendKycAnswers.map(toKycAnswer);
  if (answers[0].slug === 'yesResponse') {
    return answers.reverse();
  }

  return answers;
};

const toKycAnswer = (backendKycAnswer: BackendKycAnswer): KycAnswer => ({
  id: backendKycAnswer.id,
  slug: backendKycAnswer.slug,
});
