include ../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing.-s-lg
  .fp-vertical-spacing--line
    WalletBalanceVue
  .fp-vertical-spacing--line(v-if='club.coinsBuyable')
    .fp-horizontal-spacing.-justify-space-evenly
      .fp-horizontal-spacing--column
        .fp-action-icon(data-test='wallet.balance.buy-button' :class='{"-disabled": disabledBuyButton}' @click='goToCardsList')
          .fp-action-icon--icon
            +fp-icon('credit-card-plus', {color: 'shade-0', size: '24'})
          .fp-action-icon--text {{ t('wallet.actions.buy') }}
