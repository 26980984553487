import { BackendCaller } from '@/common/secondary/BackendCaller';
import { BackendKycQuestion, toKycQuestion } from '@/fairplayer/secondary/kyc/BackendKycQuestion';
import { KycQuestion } from '@/fairplayer/domain/kyc/KycQuestion';
import { KycRepository } from '@/fairplayer/domain/kyc/KycRepository';
import { KycAnsweredQuestion } from '@/fairplayer/domain/kyc/KycAnsweredQuestion';
import { fromKycAnsweredQuestion } from '@/fairplayer/secondary/kyc/BackendKycAnsweredQuestion';
import { Authentication } from '@/common/domain/auth/Authentication';

export class BackendKycRepository implements KycRepository {
  constructor(
    private backendCaller: BackendCaller,
    private authentication: Authentication
  ) {}

  async listKycQuestions(): Promise<KycQuestion[]> {
    return await this.backendCaller.get<BackendKycQuestion[]>('api/kyc-questions').then(({ data }) => data.map(toKycQuestion));
  }

  async send(kycAnsweredQuestions: KycAnsweredQuestion[]): Promise<void> {
    const fanUsername = await this.getFanUsername();
    const backendQuestions = kycAnsweredQuestions.map(fromKycAnsweredQuestion);

    await this.backendCaller.post<void>(`api/fans/${encodeURIComponent(fanUsername)}/kyc-answered-questions`, backendQuestions);
  }

  private async getFanUsername() {
    const authenticatedFan = await this.authentication.authenticatedFan();

    return authenticatedFan.username;
  }
}
