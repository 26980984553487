import { authenticationKey } from '@/common/domain/auth/Authentication';
import { Tokens } from '@/common/domain/token/Tokens';
import { AnnouncementVue } from '@/common/primary/announcement';
import {
  AnnouncementUi,
  createArchivedClubAnnouncementUi,
  createDevEnvironmentAnnouncementUi,
  fiatNotProcessedAnnouncementUi,
  incompleteKycAnnouncementUi,
  pendingKycAnnouncementUi,
} from '@/common/primary/announcement/Announcement.ui';
import { HorizontalMenuVue } from '@/common/primary/horizontal-menu';
import { MenuItemUi } from '@/common/primary/MenuItem.ui';
import { NotFoundVue } from '@/common/primary/not-found';
import { clubNotFoundUi } from '@/common/primary/not-found/NotFound.ui';
import { TopMenuVue } from '@/common/primary/top-menu';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { ModalVue } from '@/fairplayer/primary/club-layout/modal';
import { ClubUi, fromClub } from '@/fairplayer/primary/club/Club.ui';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Club } from '@/fairplayer/domain/club/Club';
import { PrinterVue } from '@/fairplayer/primary/club-layout/printer';
import { PageVue } from '@/fairplayer/primary/page';
import { HighlightedMediaVue } from '@/fairplayer/primary/club-layout/highlighted-media';
import { BottomModalVue } from '@/fairplayer/primary/club-layout/bottom-modal';
import { loggerKey } from '@/common/domain/Logger';
import { fromTokens } from '@/common/primary/token/Tokens.ui';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { createMenuItems } from '@/fairplayer/primary/club-layout/menu/MenuItems.ui';

import { HOMEPAGE_ROUTE_NAME } from '@/router/routes';
import { MembershipStatus } from '@/fairplayer/domain/fan/MembershipStatus';
import { themeStorageKey } from '@/fairplayer/domain/theme/ThemeStorage';

export default defineComponent({
  name: 'ClubLayout',

  components: {
    AnnouncementVue,
    BottomModalVue,
    HighlightedMediaVue,
    HorizontalMenuVue,
    NotFoundVue,
    PageVue,
    PrinterVue,
    ToastsListVue,
    TopMenuVue,
    ModalVue,
  },

  setup() {
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const fanRepository = inject(fanRepositoryKey)!;
    const logger = inject(loggerKey)!;
    const themeStorage = inject(themeStorageKey)!;
    const walletRepository = inject(walletRepositoryKey)!;
    const router = useRouter();
    const route = useRoute();

    const announcementUi = ref<AnnouncementUi | undefined>();
    const devEnvironmentAnnouncementUi = createDevEnvironmentAnnouncementUi();
    const archivedAnnouncementUi = createArchivedClubAnnouncementUi();
    const displayDevEnvironmentAnnouncement = ref(true);
    const displayClubError = ref<boolean>(false);
    const fan = ref<Fan>();
    const club = ref(Loader.loading<ClubUi | null>());
    const isAuthenticated = ref(Loader.loading<boolean>());
    const isArchived = ref(false);
    const isDevEnvironment = import.meta.env.VITE_ENV_SLUG === 'dev';
    const menuItems = ref<MenuItemUi[]>([]);

    const displayAnnouncement = computed(() => Boolean(announcementUi.value) && !router.currentRoute.value.meta.hideAnnouncement);
    const fanHasNoKyc = computed(
      () => Boolean(fan.value) && (fan.value?.kycStatus !== KycStatus.KYC_VALIDATED || !fan.value?.fiatNotProcessed.isEmpty())
    );
    const authenticationRequired = computed(() => club.value.value()!.isFoundation && !isAuthenticated.value.value());

    onMounted(async () => {
      clubRepository
        .retrieveClub()
        .then(setClubLayout)
        .catch(() => clubLayoutError());
    });

    const setClubLayout = async (clubRetrieved: Club) => {
      const changedClub = clubRepository.setCurrentClub(clubRetrieved);
      authentication.setCurrentWeb3Model(clubRetrieved.web3Model);

      const clubUi = fromClub(clubRetrieved);

      if (clubUi.isFoundation) {
        themeStorage.set('dark');
      } else {
        themeStorage.get().ifPresent(theme => themeStorage.set(theme));
      }

      await refreshLayoutOnAuthenticated(changedClub);

      menuItems.value = createMenuItems(clubUi, isAuthenticated.value.value(), fan.value?.membershipStatus === MembershipStatus.VALIDATED);
      club.value.loaded(clubUi);
      isArchived.value = clubRetrieved.state === ClubState.ARCHIVED;
    };

    const clubLayoutError = () => {
      club.value.loaded(null);
      displayClubError.value = true;
      clubRepository.clearSelectedClub();
    };

    const redirectOnAuthRequired = async () => {
      if (route.matched.some(record => record.meta.requireAuthentication)) {
        await router.push({ name: HOMEPAGE_ROUTE_NAME });
      }
    };

    const refreshLayoutOnAuthenticated = async (changedClub: boolean): Promise<void> => {
      const authenticated = await authentication.isAuthenticated();
      isAuthenticated.value.loaded(authenticated);
      if (authenticated) {
        if (changedClub) {
          await authentication.logout(false);
          isAuthenticated.value.loaded(false);
        } else {
          await refreshClubLayout();
        }
      } else {
        await redirectOnAuthRequired();
      }
    };

    const refreshClubLayout = async () => {
      announcementUi.value = undefined;
      await retrieveFan();
      await createAnnouncement();
    };

    const retrieveFan = async () => {
      await fanRepository
        .getForClub(clubRepository.getCurrentClub())
        .then((retrievedFan: Fan) => (fan.value = retrievedFan))
        .catch((error: any) => {
          logger.error('Failed to retrieve fan', error);
        });
      if (fan.value?.twoFactorValidated === false) {
        await router.push({ name: 'twoFactorAuthentication', query: { redirect: window.location.href } });
      }
    };

    const computeFanAnnouncementUi = (fan: Fan, routeLink: string | undefined): AnnouncementUi => {
      if (fan.kycStatus !== KycStatus.KYC_VALIDATED) {
        if (fan.kycStatus === KycStatus.ID_DOCUMENT_UPLOADED) {
          return pendingKycAnnouncementUi();
        }
        return incompleteKycAnnouncementUi(routeLink!);
      }
      return fiatNotProcessedAnnouncementUi(routeLink!, fan.fiatNotProcessed.map(fromTokens).orElseThrow());
    };

    const createAnnouncement = async () => {
      const fanHideKycAnnouncementPreference = fanRepository.isKycAnnouncementIgnored();
      if (fanHasNoKyc.value && !fanHideKycAnnouncementPreference) {
        announcementUi.value = computeFanAnnouncementUi(fan.value!, await computeRouteLink());
      } else {
        announcementUi.value = undefined;
      }
    };

    const hideKycAnnouncement = async () => {
      fanRepository.ignoreKycAnnouncement();
      await createAnnouncement();
    };

    const hideDevAnnouncement = () => {
      displayDevEnvironmentAnnouncement.value = false;
    };

    const computeRouteLink = async (): Promise<string | undefined> => {
      if (fan.value?.kycStatus !== KycStatus.KYC_VALIDATED) {
        if (fan.value?.kycStatus === KycStatus.ID_DOCUMENT_UPLOADED) {
          return undefined;
        }
        return `${window.location.origin}/clubs/${clubRepository.getCurrentSlug()}/profile`;
      }
      return await computeTokenRedirection();
    };

    const computeTokenRedirection = async (): Promise<string> => {
      const toCreditToken: Tokens = fan.value!.fiatNotProcessed.orElseThrow();
      return `${walletRepository.computeSuccessFrontendRedirectionUrl(toCreditToken)}&success=1`;
    };

    return {
      authenticationRequired,
      club,
      clubNotFoundUi,
      displayClubError,
      fan,
      announcementUi,
      archivedAnnouncementUi,
      devEnvironmentAnnouncementUi,
      displayAnnouncement,
      displayDevEnvironmentAnnouncement,
      isAuthenticated,
      isArchived,
      isDevEnvironment,
      menuItems,
      hideKycAnnouncement,
      hideDevAnnouncement,
      refreshClubLayout,
    };
  },
});
