include ../../../common/primary/icon/icon.mixin.pug

.fp-announcement(data-test='announcement' :class='{"-info": !announcementUi.isWarning}')
  .fp-announcement--icon(v-if='announcementUi.isWarning' data-test='announcement.icon')
    +fp-icon('warning', {size: '18', color: 'warning'})
  .fp-announcement--text
    span.fp-text(data-test='message') {{ t(announcementUi.messageKey, announcementUi.messageArguments) }}
    a.fp-link(v-if='announcementUi.routeLink && announcementUi.linkKey' @click='goTo' data-test='link') {{ t(announcementUi.linkKey) }}
  .fp-announcement--close(v-if='announcementUi.showCloseAction' data-test='close' @click='close')
    +fp-icon('close', {size: '12'})
