import {
  documentFailureKycStepsUi,
  validationFailureKycStepsUi,
  validationIncompleteKycStepsUi,
  validationPendingKycStepsUi,
  validationQuestionsAnsweredKycStepsUi,
  ValidationStepsUi,
  validationSuccessKycStepsUi,
} from '@/fairplayer/primary/profile/kyc-summary/kyc-validation/ValidationStep.ui';
import { Fan } from '@/fairplayer/domain/fan/Fan';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';
import { IdDocumentErrorType } from '@/fairplayer/domain/fan/IdDocumentError';

export interface KycValidationUi {
  descriptionKey?: 'pending' | 'completed';
  errorKey?: 'refused' | 'idDocumentUnreadable' | 'idDocumentExpired' | 'idDocumentMessage' | 'idDocumentUnknownError';
  idDocumentErrorMessage?: string;
  steps: ValidationStepsUi;
  resetKycStatus?: KycStatus;
}

const computeKycValidationStepsUi = (fan: Fan) => {
  switch (fan.kycStatus) {
    case KycStatus.TOS_VALIDATED:
    case KycStatus.FAN_PERSONAL_INFO_FILLED:
      return validationIncompleteKycStepsUi();
    case KycStatus.KYC_QUESTIONS_ANSWERED:
      return validationQuestionsAnsweredKycStepsUi();
    case KycStatus.ID_DOCUMENT_REFUSED:
      return documentFailureKycStepsUi();
    case KycStatus.KYC_REFUSED:
      return validationFailureKycStepsUi();
    case KycStatus.KYC_VALIDATED:
      return validationSuccessKycStepsUi();

    default:
      return validationPendingKycStepsUi();
  }
};

const descriptionKeyByFanKycStatus = (fan: Fan) => {
  switch (fan.kycStatus) {
    case KycStatus.KYC_VALIDATED:
      return 'completed';
    case KycStatus.ID_DOCUMENT_UPLOADED:
      return 'pending';

    default:
      return undefined;
  }
};

const errorKeyByIdDocumentError = (errorType: IdDocumentErrorType) => {
  switch (errorType) {
    case IdDocumentErrorType.UNREADABLE:
      return 'idDocumentUnreadable';
    case IdDocumentErrorType.EXPIRED:
      return 'idDocumentExpired';
    case IdDocumentErrorType.MESSAGE:
      return 'idDocumentMessage';
    default:
      return 'idDocumentUnknownError';
  }
};

const errorKeyByFanKycStatus = (fan: Fan) => {
  switch (fan.kycStatus) {
    case KycStatus.ID_DOCUMENT_REFUSED:
      return errorKeyByIdDocumentError(fan.idDocumentError.orElseThrow().type);
    case KycStatus.KYC_REFUSED:
      return 'refused';

    default:
      return undefined;
  }
};

const resetKycStatusByFanCurrentKycStatus = (fan: Fan) => {
  switch (fan.kycStatus) {
    case KycStatus.KYC_REFUSED:
      return KycStatus.KYC_NOT_STARTED;
    case KycStatus.ID_DOCUMENT_REFUSED:
      return KycStatus.KYC_QUESTIONS_ANSWERED;

    default:
      return undefined;
  }
};

export const fromFan = (fan: Fan): KycValidationUi => ({
  descriptionKey: descriptionKeyByFanKycStatus(fan),
  errorKey: errorKeyByFanKycStatus(fan),
  idDocumentErrorMessage: fan.idDocumentError.flatMap(error => error.message).orElseUndefined(),
  steps: computeKycValidationStepsUi(fan),
  resetKycStatus: resetKycStatusByFanCurrentKycStatus(fan),
});
