import { Card } from '@/fairplayer/domain/fan/card/Card';

export interface CardUi {
  id: string;
  cardType: string;
  expirationDate: string;
  alias: string;
  isValid: boolean;
}

const toHumanAlias = (alias: string): string => {
  return `${alias.slice(0, 4)} ${alias.slice(4, 8)} ${alias.slice(8, 12)} ${alias.slice(12, 16)}`;
};

export const fromCard = (card: Card): CardUi => ({
  id: card.id,
  cardType: card.cardType,
  expirationDate: card.expirationDate.toHuman(),
  alias: toHumanAlias(card.alias),
  isValid: card.isValid,
});
