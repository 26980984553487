include ../../../common/primary/icon/icon.mixin.pug

PageVue(data-test='posts')
  template(v-slot:body)
    .fp-vertical-spacing(v-if='!postList.isLoading()')
      .fp-vertical-spacing--line(v-if='!postList.value().length' data-test='alert')
        .fp-alert.-info
          +fp-icon('info', {color: 'info', size: '18'})
          span.fp-text.-paragraph {{ t('posts.alert.emptyPosts') }}
      template(v-if='postList.value().length')
        .fp-vertical-spacing--line
          h2.fp-title {{ t('posts.page.title') }}
        .fp-vertical-spacing--line(v-if='postList.value().length' v-for='(post, index) in postList.value()' :key='index' :data-test='`post-${index}`' :id='post.slug')
          .fp-illustrated-item(:data-test='`post-${index}`')
            .fp-image.-lg.-rounded
              FairplayerImageVue(:url='post.imageUrl' :alt='post.name' highlightable data-test='image')
            .fp-illustrated-item--content
              .fp-vertical-spacing.-s-xs
                .fp-vertical-spacing--line
                  .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}
                .fp-vertical-spacing--line
                  .fp-text.-text-semi-bold.-color-shade-900(data-test='name') {{ post.name }}
                .fp-vertical-spacing--line
                  router-link.fp-link.-secondary(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-page') {{ t('posts.seeMore') }}
