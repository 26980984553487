import { Fan } from '@/fairplayer/domain/fan/Fan';
import { KycStatus } from '@/fairplayer/domain/fan/KycStatus';

interface KycSummaryDisplayItemUi {
  shouldDisplay: boolean;
  textKey?: string;
}

export type KycSummaryDisplayUI = {
  shouldDisplayKycValidation: boolean;
  descriptionTextKey: string;
  redirectButton: KycSummaryDisplayItemUi;
  cardTitle: KycSummaryDisplayItemUi;
};

const shouldDisplayKycValidation = (fan: Fan) => {
  return ![KycStatus.KYC_NOT_STARTED, KycStatus.KYC_REFUSED, KycStatus.KYC_VALIDATED].includes(fan.kycStatus);
};

const shouldDisplayRedirectKycButton = (fan: Fan) => {
  return ![KycStatus.ID_DOCUMENT_UPLOADED, KycStatus.KYC_REFUSED, KycStatus.KYC_VALIDATED].includes(fan.kycStatus);
};

const toButtonTextKey = (kycStatus: KycStatus) => {
  switch (kycStatus) {
    case KycStatus.KYC_NOT_STARTED:
      return 'register';
    case KycStatus.TOS_VALIDATED:
    case KycStatus.FAN_PERSONAL_INFO_FILLED:
    case KycStatus.KYC_QUESTIONS_ANSWERED:
      return 'resumeRegistration';
    case KycStatus.ID_DOCUMENT_REFUSED:
      return 'documentError';
    default:
      return undefined;
  }
};

const toCardTextKey = (kycStatus: KycStatus) => {
  if (kycStatus === KycStatus.ID_DOCUMENT_UPLOADED) {
    return 'pending';
  }
  if (kycStatus === KycStatus.KYC_REFUSED) {
    return 'invalidProfile';
  }
  return 'joinClub';
};

export const toKycSummaryDisplayUI = (fan: Fan): KycSummaryDisplayUI => ({
  shouldDisplayKycValidation: shouldDisplayKycValidation(fan),
  descriptionTextKey: toCardTextKey(fan.kycStatus),
  redirectButton: {
    shouldDisplay: shouldDisplayRedirectKycButton(fan),
    textKey: toButtonTextKey(fan.kycStatus),
  },
  cardTitle: {
    shouldDisplay: true,
    textKey: toCardTextKey(fan.kycStatus),
  },
});
