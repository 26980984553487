include ../../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing(data-test='kyc-validation')
  .fp-vertical-spacing--line.-align-center(v-if='kycValidationUi.descriptionKey')
    span.fp-text(data-test='description') {{ t(`kycValidation.description.${kycValidationUi.descriptionKey}`) }}
  .fp-vertical-spacing--line(v-for='kycStep in kycValidationUi.steps' :key='kycStep.stepKey' :data-test='kycStep.stepKey')
    .fp-listing-element
      i(:class='`fp-icon ${kycStep.iconClass} -s24`' :data-test='`${kycStep.stepKey}.icon`')
      span.fp-text {{ t(`kycValidation.step.${kycStep.stepKey}.${kycStep.textKey}`) }}
  .fp-vertical-spacing--line(v-if='kycValidationUi.errorKey' data-test='tooltip')
    .fp-card
      .fp-icon-with-text
        +fp-icon('warning', {color: 'warning', size: '18'})
        .fp-icon-with-text--text
          .fp-icon-with-text--text-line
            span.fp-text.-paragraph {{ t(`kycValidation.description.${kycValidationUi.errorKey}`, { message: kycValidationUi.idDocumentErrorMessage }) }}
