import { PageVue } from '@/fairplayer/primary/page';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';

export default {
  name: 'PaymentFailed',

  components: { PageVue, FairplayerButtonVue },

  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const route = useRoute();

    const failureReason = route.query.reason;

    const buyTokens = async () => await router.push({ name: 'cardsList' });

    return { t, buyTokens, failureReason };
  },
};
