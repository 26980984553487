import { Club } from '@/fairplayer/domain/club/Club';
import { euro } from '@/common/domain/token/Fiat';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { BackendPartner, toPartner } from '@/fairplayer/secondary/club/BackendPartner';
import { BackendGiveaway, toGiveaway } from '@/fairplayer/secondary/club/BackendGiveaway';
import { Optional } from '@/common/domain/Optional';
import { LocalDate } from '@/common/domain/LocalDate';
import { BackendClubSummary, toClubSummary } from '@/fairplayer/secondary/club/BackendClubSummary';
import { Language } from '@/common/domain/Language';
import { ClubWeb3Model } from '@/common/domain/club/ClubWeb3Model';
import { ClubModel } from '@/fairplayer/domain/club/ClubModel';
import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';

export interface BackendClub {
  id: string;
  name: string;
  slug: string;
  model: ClubModel;
  web3Model: ClubWeb3Model;
  logoUrl: string;
  bannerUrl: string;
  ticker: string;
  currentPrice: number;
  contactEmail: string;
  state: ClubState;
  upcomingDate?: string;
  coinsEnabled: boolean;
  fiatAccepted: boolean;
  membershipFee?: BackendFiat;
  languages: Language[];
  partners: BackendPartner[];
  activeGiveaways: BackendGiveaway[];
  linkedClubs: BackendClubSummary[];
}

export const toClub = (backendClub: BackendClub): Club => ({
  id: backendClub.id,
  name: backendClub.name,
  slug: backendClub.slug,
  model: backendClub.model,
  logoUrl: backendClub.logoUrl,
  bannerUrl: backendClub.bannerUrl,
  web3Model: backendClub.web3Model,
  ticker: backendClub.ticker,
  currentPrice: euro(backendClub.currentPrice),
  contactEmail: backendClub.contactEmail,
  state: backendClub.state,
  upcomingDate: Optional.ofUndefinable(backendClub.upcomingDate).map(LocalDate.of),
  coinsEnabled: backendClub.coinsEnabled,
  fiatAccepted: backendClub.fiatAccepted,
  membershipFee: Optional.ofUndefinable(backendClub.membershipFee).map(toFiat),
  languages: backendClub.languages,
  partners: Optional.ofUndefinable(backendClub.partners).orElse([]).map(toPartner),
  activeGiveaways: Optional.ofUndefinable(backendClub.activeGiveaways).orElse([]).map(toGiveaway),
  linkedClubs: backendClub.linkedClubs.map(toClubSummary),
});
