import { InjectionKey } from 'vue';
import { Card } from '@/fairplayer/domain/fan/card/Card';
import { CardToCreate } from '@/fairplayer/domain/fan/card/CardToCreate';

export const cardRepositoryKey: InjectionKey<CardRepository> = Symbol();

export interface CardRepository {
  list(): Promise<Card[]>;
  create(card: CardToCreate): Promise<Card>;
}
