include ../../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing
      .fp-vertical-spacing--line.-align-center
        h1.fp-title {{ t('wallet.creditWallet.title') }}
      .fp-vertical-spacing--line.-align-center(v-if='conversionToken.isLoading()')
        +fp-icon('loader', {color: 'shade-400', size: '24', spin: true})
      template(v-else)
        .fp-vertical-spacing--line.-justify-center(data-test='conversion')
          .fp-icon-with-text
            img.fp-illustration.-sm.-token(alt='Token')
            .fp-icon-with-text--text
              .fp-icon-with-text--text-line
                span.fp-text.-text-semi-bold.-color-shade-900  {{ t('wallet.creditWallet.conversion') }}
              .fp-icon-with-text--text-line
                span.fp-text.-color-shade-400 {{ conversionToken.value().totalCost.text }} = {{ conversionToken.value().coins.text }}
        .fp-vertical-spacing--line.-align-center(v-if='showNotEnoughFiat' data-test='not-enough-fiat')
          .fp-alert.-warning
            +fp-icon('warning', {color: 'warning', size: '18'})
            span.fp-text.-text-sm {{ t('wallet.creditWallet.notEnoughFiat') }}
        .fp-vertical-spacing--line.-align-center(v-else-if='!tokenPaymentAvailability.accepted' data-test='tokens-payment-disabled')
          .fp-alert.-warning
            +fp-icon('warning', {color: 'warning', size: '18'})
            span.fp-text.-text-sm {{ t('wallet.creditWallet.tokensPaymentDisabled') }}
        .fp-vertical-spacing--line(v-else-if='!showNotEnoughFiat')
          .fp-card
            form.fp-vertical-spacing(@submit.prevent='openConfirmModal' data-test='credit-wallet-form')
              .fp-vertical-spacing--line.-align-center
                h3.fp-title {{ t('wallet.creditWallet.amount') }}
              .fp-vertical-spacing--line.-align-center(v-if='showMaxFiatWarning' data-test='max-fiat-warning')
                .fp-alert.-warning
                  +fp-icon('warning', {color: 'warning', size: '18'})
                  span.fp-text.-text-sm {{ t('wallet.creditWallet.maxFiatWarning', { maxFiatValue }) }}
              .fp-vertical-spacing--line
                .fp-converter.-secondary
                  label.fp-converter-input
                    .fp-converter-input--ticker EUR
                    input.fp-converter-input--input(type='number' :min='minFiatValue' :max='maxFiatValue' step='0.02' v-model='toCreditTokensUi.totalCost.value' @input='updatedFiatValue' data-test='fiat-value' @focus='$event.target.select()' required)
                    .fp-converter-input--icon
                      +fp-icon('euro', {size: '18'})
                  label.fp-converter-input
                    .fp-converter-input--ticker(data-test='to-credit-tokens-ticker') {{ toCreditTokensUi.coins.ticker }}
                    input.fp-converter-input--input(type='number' :max='maxCoinsAmount' v-model='toCreditTokensUi.coins.amount' @input='updatedCoinsAmount' @focus='$event.target.select()' data-test='coins-amount')
                    .fp-converter-input--icon
                      +fp-icon('coin', {size: '18'})
                  .fp-converter--icon
                    +fp-icon('arrows-down-up', {color: 'shade-900', size: '18'})
              .fp-vertical-spacing--line
                label.fp-text.-text-sm.-color-shade-600 {{ t('wallet.creditWallet.securityCodeDescription') }}
                  input.fp-input-text(pattern='\\d{3,3}' :placeholder=`t('wallet.creditWallet.securityCode')` v-model='securityCode' data-test='security-code' required)
              .fp-vertical-spacing--line.-justify-center
                .fp-horizontal-spacing
                  .fp-horizontal-spacing--column
                    img.fp-illustration.-kriptown-logo(alt='Logo Kriptown')
                  .fp-horizontal-spacing--column
                    img.fp-illustration.-mangopay-logo(alt='Logo Mangopay')
              .fp-vertical-spacing--line
                FairplayerButtonVue(data-test='credit-wallet' expand type='submit' :loading='isCreditingWallet' :text='t("wallet.creditWallet.confirmCreditWallet")')
