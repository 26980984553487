import { Auction } from '@/fairplayer/domain/exclusive/Auction';
import { BidUi, fromBid } from '@/fairplayer/primary/marketplace/Bid.ui';
import { FanId } from '@/common/domain/FanId';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface AuctionUi {
  id: string;
  startingPrice: TokensUi;
  currentPrice: TokensUi;
  startDate: Date;
  endDate: Date;
  bids: BidUi[];
}

export const fromAuction = (auction: Auction, fanId?: FanId): AuctionUi => ({
  id: auction.id,
  startingPrice: fromTokens(auction.startingPrice),
  currentPrice: fromTokens(auction.currentPrice),
  startDate: auction.startDate,
  endDate: auction.endDate,
  bids: auction.bids.map(bid => fromBid(bid, bid.bidder.id === fanId)),
});
