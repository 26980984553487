import { CardToCreate } from '@/fairplayer/domain/fan/card/CardToCreate';
import { CardType } from '@/fairplayer/domain/fan/card/CardType';
import { CardExpirationDate } from '@/fairplayer/domain/fan/card/CardExpirationDate';

export interface CardToCreateUi {
  expirationDate: string;
  cardNumber: string;
  securityCode: string;
}

export const emptyCardToCreateUi = (): CardToCreateUi => ({
  expirationDate: '',
  cardNumber: '',
  securityCode: '',
});

export const toCardToCreate = (ui: CardToCreateUi): CardToCreate => ({
  expirationDate: new CardExpirationDate(ui.expirationDate),
  cardNumber: ui.cardNumber,
  securityCode: ui.securityCode,
  cardType: CardType.CB_VISA_MASTERCARD,
  currency: 'EUR',
});
