import { PropType, ref } from 'vue';
import { PresentationCardVue } from '@/common/primary/presentation-card';
import { CarouselVue } from '@/common/primary/carousel';
import { MemberUi } from '@/fairplayer/primary/member/Member.ui';
import { MemberCardVue } from '@/fairplayer/primary/member/members-list/member-card';

export default {
  name: 'MembersCarousel',

  components: { PresentationCardVue, CarouselVue, MemberCardVue },

  props: {
    members: {
      type: Array as PropType<MemberUi[]>,
      required: true,
    },
  },

  setup() {
    const currentIndex = ref(0);

    const setCurrentIndex = (index: number) => {
      currentIndex.value = index;
    };

    return {
      setCurrentIndex,
      currentIndex,
    };
  },
};
