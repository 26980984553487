.fp-vertical-spacing(data-test='posts-list')
  .fp-vertical-spacing--line
    h2.fp-title(data-test='title') {{ t('programs.page.newsFeed') }}
  .fp-vertical-spacing--line(v-for='(post, id) in posts' :key='id')
    .fp-vertical-spacing.-s-sm
      .fp-vertical-spacing--line
        .fp-illustrated-item(:data-test='`post-${id}`')
          .fp-image.-lg.-rounded
            FairplayerImageVue(:url='post.imageUrl' highlightable data-test='image-post')
          .fp-illustrated-item--content
            .fp-vertical-spacing.-s-xs
              .fp-vertical-spacing--line
                .fp-text.-color-shade-500.-text-sm(data-test='date') {{ post.date }}
              .fp-vertical-spacing--line
                .fp-text.-text-semi-bold.-color-shade-900(data-test='name') {{ post.name }}
              .fp-vertical-spacing--line
                router-link.fp-link.-secondary(:to='{name: \'postPage\', params: { postSlug: post.slug }}' data-test='post-page') {{ t('posts.seeMore') }}
