include ../../../../../../common/primary/icon/icon.mixin.pug

form.fp-vertical-spacing(@submit.prevent='confirm' data-test='credit-wallet-confirm-modal.form')
  .fp-vertical-spacing--line.-align-center
    +fp-icon('warning', {size: '50', color: 'warning'})
  .fp-vertical-spacing--line.-align-center
    h2.fp-title {{ t('wallet.creditWallet.confirmation.title') }}
  .fp-vertical-spacing--line
    .fp-checkable-element
      .fp-checkable-element--checkbox
        input.fp-checkbox#checkAssetsSales(type='checkbox' v-model='checkAssetsSalesInput' data-test='credit-wallet-confirm-modal.check-assets-sales' required)
      .fp-checkable-element--label
        label(for='checkAssetsSales') {{ t('wallet.creditWallet.confirmation.checkAssetsSales') }}
  .fp-vertical-spacing--line
    .fp-checkable-element
      .fp-checkable-element--checkbox
        input.fp-checkbox#checkUseTokens(type='checkbox' v-model='checkUseTokensInput' data-test='credit-wallet-confirm-modal.check-use-tokens' required)
      .fp-checkable-element--label
        label(for='checkUseTokens') {{ t('wallet.creditWallet.confirmation.checkUseTokens') }}
  .fp-vertical-spacing--line
    .fp-checkable-element
      .fp-checkable-element--checkbox
        input.fp-checkbox#checkSfcFairplayer(type='checkbox' v-model='checkSfcFairplayerInput' data-test='credit-wallet-confirm-modal.check-sfc-fairplayer' required)
      .fp-checkable-element--label
        label(for='checkSfcFairplayer') {{ t('wallet.creditWallet.confirmation.checkSfcFairplayer') }}
  .fp-vertical-spacing--line.-align-center
    a.fp-link(target='_blank' :href='url') {{ t('wallet.creditWallet.confirmation.seeTos') }}
  .fp-vertical-spacing--line.-align-center
    img.fp-illustration.-kriptown-logo(alt='Logo Kriptown')
  .fp-vertical-spacing--line.-justify-center
    .fp-horizontal-spacing
      .fp-horizontal-spacing--column
        FairplayerButtonVue(data-test='credit-wallet-confirm-modal.cancel' secondary :text='t("wallet.creditWallet.confirmation.cancel")' @click='cancel')
      .fp-horizontal-spacing--column
        FairplayerButtonVue(data-test='credit-wallet-confirm-modal.confirm' :disabled='isConfirmDisabled' type='submit' :text='t("wallet.creditWallet.confirmation.confirm")')
