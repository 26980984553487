.fp-vertical-spacing
  .fp-vertical-spacing--line
    h2.fp-title {{ t('members.conversations') }}
  .fp-vertical-spacing--line(v-for='(conversation, index) in interlocutorsMessages' :key='index' :data-test='`conversation-${index}`')
    router-link.fp-link.-no-text-decoration(:to='{name: \'chatPage\', params: { memberId: conversation.interlocutorId }}')
      .fp-horizontal-spacing.-s-sm.-items-center
        .fp-horizontal-spacing--column
          .fp-image.-circled
            img(:src='conversation.interlocutorPictureUrl' alt='Image')
        .fp-horizontal-spacing--column.-expand
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              span.fp-text.-color-shade-900.-text-semi-bold(data-test='interlocutor-name') {{ conversation.interlocutorFirstName }} {{ conversation.interlocutorLastName }}
            .fp-vertical-spacing--line
              .fp-text.-color-shade-500.-lines-1.-text-sm(data-test='last-message')
                span(v-if='!conversation.messages[conversation.messages.length - 1].received' data-test='you') {{ t('chat.you') }}&nbsp;
                | {{ conversation.messages[conversation.messages.length - 1].text }}
        .fp-horizontal-spacing--column(v-if='conversation.numberOfUnreadMessages > 0' data-test='number-of-unread-message')
          .fp-chip {{ conversation.numberOfUnreadMessages }}
