import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';

export default defineComponent({
  name: 'ForgotCode',
  components: { FairplayerButtonVue },
  emits: ['email-filled'],

  setup(props, { emit }) {
    const { t } = useI18n();
    const email = ref('');

    const sendCode = async () => {
      emit('email-filled', { value: email.value });
    };

    return {
      email,
      sendCode,
      t,
    };
  },
});
