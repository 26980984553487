import { Optional } from '@/common/domain/Optional';
import { ThemeStorage } from '@/fairplayer/domain/theme/ThemeStorage';

const THEME = 'theme';

export class ThemeLocalStorage implements ThemeStorage {
  constructor(
    private storage: Storage,
    private document: Document
  ) {}

  get(): Optional<string> {
    return Optional.ofUndefinable(this.storage.getItem(THEME));
  }

  save(theme: string): void {
    this.storage.setItem(THEME, theme);
  }

  set(theme: string): void {
    this.document.getElementsByTagName('html')[0].setAttribute('data-theme', theme);
  }
}
