import { BackendKycAnswer } from '@/fairplayer/secondary/kyc/BackendKycAnswer';
import { KycAnsweredQuestion } from '@/fairplayer/domain/kyc/KycAnsweredQuestion';
import { fromKycAnswer } from '@/fairplayer/primary/kyc-questions/KycAnswer.ui';

export interface BackendKycAnsweredQuestion {
  slug: string;
  answer: BackendKycAnswer;
}

export const fromKycAnsweredQuestion = (kycAnsweredQuestion: KycAnsweredQuestion): BackendKycAnsweredQuestion => ({
  slug: kycAnsweredQuestion.slug,
  answer: fromKycAnswer(kycAnsweredQuestion.answer),
});
