import { CardToCreate } from '@/fairplayer/domain/fan/card/CardToCreate';
import { KriptownViewerCreateCardRegistration } from '@/fairplayer/secondary/fan/card/KriptownViewerCreateCardRegistration';
import { CardRegistration } from 'mangopay-cardregistration-js-kit';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { KnownError } from '@/common/domain/KnownError';
import { MangoPayCardRegistration } from '@/fairplayer/secondary/fan/card/MangoPayCardRegistration';

const knownErrors: Record<number, string> = {
  105202: 'cardNumberFormat',
  105203: 'pastExpiryDate',
  105204: 'cvvFormat',
};

const toCardData = (cardToCreate: CardToCreate) => ({
  cardNumber: cardToCreate.cardNumber,
  cardExpirationDate: cardToCreate.expirationDate.toComputer(),
  cardCvx: cardToCreate.securityCode,
  cardType: cardToCreate.cardType,
});

export class MangoPayCardRepository {
  constructor(
    private mangoPay: MangoPayCardRegistration,
    private alertBus: AlertBus
  ) {}

  async createCard(cardToCreate: CardToCreate, kriptownCardRegistration: KriptownViewerCreateCardRegistration): Promise<CardRegistration> {
    this.mangoPay.baseURL = import.meta.env.VITE_MANGOPAY_URL;
    this.mangoPay.clientId = import.meta.env.VITE_MANGOPAY_CLIENT_ID;
    this.mangoPay.init({
      Id: kriptownCardRegistration.id,
      cardRegistrationURL: kriptownCardRegistration.cardRegistrationURL,
      preregistrationData: kriptownCardRegistration.preregistrationData,
      accessKey: kriptownCardRegistration.accessKey,
    });

    return new Promise(resolve =>
      this.mangoPay.registerCard(
        toCardData(cardToCreate),
        createdCard => resolve(createdCard),
        e => {
          const knownError = knownErrors[+e.ResultCode];
          if (knownError) {
            throw new KnownError(`errors.mangoPay.${knownError}`);
          }

          this.alertBus.alert({ message: 'errors.mangoPay.unknown', type: 'danger' });
          throw new Error(`[Error ${e.ResultCode}] ${e.ResultMessage}`);
        }
      )
    );
  }
}
