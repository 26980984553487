import { KriptownCaller } from '@/common/secondary/KriptownCaller';
import { Card } from '@/fairplayer/domain/fan/card/Card';
import { KriptownViewerWithCardsResult } from '@/fairplayer/secondary/fan/KriptownViewerWithCardsResult';
import { toCard } from '@/fairplayer/secondary/fan/card/KriptownCard';
import { CardToCreate } from '@/fairplayer/domain/fan/card/CardToCreate';
import { KriptownViewerCreateCardRegistration } from '@/fairplayer/secondary/fan/card/KriptownViewerCreateCardRegistration';
import { KriptownViewerCreateCardRegistrationResult } from '@/fairplayer/secondary/fan/card/KriptownViewerCreateCardRegistrationResult';

const GET_VIEWER_CARDS = `
  query ViewerCards {
    viewer {
      cards {
        id
        cardType
        expirationDate
        alias
        validity
      }
    }
  }
`;

const CREATE_VIEWER_CARDS = `
mutation ViewerCreateCardRegistration($input: CreateCardRegistrationInput!) {
  createCardRegistration(input: $input) {
    accessKey
    cardRegistrationURL
    id
    preregistrationData
  }
}
`;

export class KriptownCardRepository {
  constructor(private kriptownCaller: KriptownCaller) {}

  list(): Promise<Card[]> {
    return this.kriptownCaller
      .query<KriptownViewerWithCardsResult>(GET_VIEWER_CARDS)
      .then(response => response.data.viewer.cards.map(toCard));
  }

  createCardRegistration(cardToCreate: CardToCreate): Promise<KriptownViewerCreateCardRegistration> {
    const cardRegistrationInput = {
      currency: cardToCreate.currency,
      cardType: cardToCreate.cardType,
    };
    return this.kriptownCaller
      .mutate<KriptownViewerCreateCardRegistrationResult>(CREATE_VIEWER_CARDS, cardRegistrationInput)
      .then(response => response.data?.createCardRegistration!);
  }
}
