import { computed, defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { ProgramUi } from '@/fairplayer/primary/programs/Program.ui';
import { fromProgramUi } from '@/common/primary/presentation-card/PresentationCardItem.ui';
import { ProgramsCarouselVue } from '@/fairplayer/primary/program-carousel';

export default defineComponent({
  name: 'OtherPrograms',

  components: { ProgramsCarouselVue },

  props: {
    otherPrograms: {
      type: Array as PropType<ProgramUi[]>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();

    const carouselItems = computed(() => props.otherPrograms.map(fromProgramUi));

    return { t, carouselItems };
  },
});
