const SUCCESS_ICON_CLASS = 'fp-glyph-check-circle -color-success';
const PENDING_ICON_CLASS = 'fp-glyph-hourglass-low';
const DANGER_ICON_CLASS = 'fp-glyph-warning -color-danger';
const WARNING_ICON_CLASS = 'fp-glyph-warning -color-warning';

type StepKey = 'personalInfo' | 'uploadedDocuments' | 'validation';
type IconClass = typeof SUCCESS_ICON_CLASS | typeof PENDING_ICON_CLASS | typeof DANGER_ICON_CLASS | typeof WARNING_ICON_CLASS;

export interface ValidationStepUi {
  stepKey: StepKey;
  iconClass: IconClass;
  textKey: 'notCompleted' | 'completed' | 'incomplete' | 'pending';
}

export type ValidationStepsUi = [ValidationStepUi, ValidationStepUi, ValidationStepUi] | [ValidationStepUi, ValidationStepUi];

const successStepUi = (stepKey: StepKey): ValidationStepUi => ({
  stepKey,
  iconClass: SUCCESS_ICON_CLASS,
  textKey: 'completed',
});
const warningStepUi = (stepKey: StepKey): ValidationStepUi => ({
  stepKey,
  iconClass: WARNING_ICON_CLASS,
  textKey: 'notCompleted',
});
const pendingStepUi = (stepKey: StepKey): ValidationStepUi => ({
  stepKey,
  iconClass: PENDING_ICON_CLASS,
  textKey: 'pending',
});
const failureStepUi = (stepKey: StepKey): ValidationStepUi => ({
  stepKey,
  iconClass: DANGER_ICON_CLASS,
  textKey: 'incomplete',
});

const personalInfoSuccess = () => successStepUi('personalInfo');
const personalInfoWarning = () => warningStepUi('personalInfo');
const personalInfoFailure = () => failureStepUi('personalInfo');

const uploadedDocumentsSuccess = () => successStepUi('uploadedDocuments');
const uploadedDocumentsWarning = () => warningStepUi('uploadedDocuments');
const uploadedDocumentsFailure = () => failureStepUi('uploadedDocuments');

const validationSuccess = () => successStepUi('validation');
const validationPending = () => pendingStepUi('validation');
const validationFailure = () => failureStepUi('validation');

export const validationIncompleteKycStepsUi = (): ValidationStepsUi => [personalInfoWarning(), uploadedDocumentsWarning()];
export const validationQuestionsAnsweredKycStepsUi = (): ValidationStepsUi => [personalInfoSuccess(), uploadedDocumentsWarning()];

export const validationPendingKycStepsUi = (): ValidationStepsUi => [
  personalInfoSuccess(),
  uploadedDocumentsSuccess(),
  validationPending(),
];

export const documentFailureKycStepsUi = (): ValidationStepsUi => [personalInfoSuccess(), uploadedDocumentsFailure()];

export const validationSuccessKycStepsUi = (): ValidationStepsUi => [
  personalInfoSuccess(),
  uploadedDocumentsSuccess(),
  validationSuccess(),
];

export const validationFailureKycStepsUi = (): ValidationStepsUi => [
  personalInfoFailure(),
  uploadedDocumentsFailure(),
  validationFailure(),
];
