import { FanIdentity } from '@/common/domain/auth/FanIdentity';
import { Auth0Client, Auth0ClientOptions, LogoutOptions, User } from '@auth0/auth0-spa-js';
import { Optional } from '@/common/domain/Optional';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import { Logger } from '@/common/domain/Logger';
import { AuthenticationProvider } from '@/common/domain/auth/AuthenticationProvider';
import { LoginContextRepository } from '@/common/domain/login/LoginContextRepository';
import { Credential } from '@/common/domain/auth/Credential';
import { AuthState } from '@/common/domain/auth/AuthState';

const APPLICATION_BASE_URL = window.location.origin;

export const createAuth0ClientWith = (createAuth0ClientFn: (options: Auth0ClientOptions) => Promise<Auth0Client>, alertBus: AlertBus) =>
  createAuth0ClientFn({
    domain: import.meta.env.VITE_KRIPTOWN_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_KRIPTOWN_AUTH0_CLIENT_ID,
    authorizationParams: {
      prompt: 'login',
      redirect_uri: `${APPLICATION_BASE_URL}/login/callback`,
      audience: import.meta.env.VITE_KRIPTOWN_AUTH0_AUDIENCE,
    },
    cacheLocation: 'localstorage',
  }).catch(error => {
    alertBus.alert({ message: 'errors.authentication.unknown', type: 'danger' });
    throw new Error(error);
  });

export class AuthenticationAuth0 implements AuthenticationProvider {
  constructor(
    private client: Auth0Client,
    private loginContextRepository: LoginContextRepository,
    private alertBus: AlertBus,
    private logger: Logger
  ) {}

  async login(kycNeeded = false): Promise<void> {
    const redirectUri = `${APPLICATION_BASE_URL}/login/callback`;
    this.loginContextRepository.storeLoginContext({
      redirectUrl: window.location.href,
      kycNeeded,
    });
    return this.client
      .loginWithRedirect({
        authorizationParams: {
          redirect_uri: redirectUri,
        },
      })
      .catch(error => this.throwError(error));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async loginWithCredential(credential: Credential, kycNeeded?: boolean): Promise<AuthState> {
    return { authenticationCompleted: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async googleLogin(kycNeeded = false): Promise<void> {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async signUp(credential: Credential): Promise<void> {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async confirmEmail(confirmationCode: string, email: string): Promise<void> {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async resendConfirmationCode(email: string): Promise<void> {
    return;
  }

  async logout(redirect: boolean): Promise<void> {
    try {
      await this.client.logout(this.getLogoutOptions(redirect));
    } catch (error: any) {
      this.throwError(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async askResetPassword(email: string): Promise<void> {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async confirmResetPassword(credential: Credential, confirmationCode: string): Promise<void> {
    return;
  }

  private getLogoutOptions(redirect: boolean): LogoutOptions {
    if (redirect) {
      return {
        logoutParams: {
          returnTo: APPLICATION_BASE_URL,
        },
      };
    }

    return {
      openUrl: false,
    };
  }

  async handleRedirectCallback(): Promise<any> {
    return this.client.handleRedirectCallback().catch(error => this.throwError(error));
  }

  async optionalAuthenticatedFan(): Promise<Optional<FanIdentity>> {
    let auth0User: User | undefined;
    try {
      auth0User = await this.client.getUser();
    } catch (error: any) {
      this.logger.error('Failed to get user', error);
      return Optional.empty();
    }
    if (!auth0User) {
      return Optional.empty();
    }
    return Optional.of<FanIdentity>({
      name: this.extractUserNameFrom(auth0User),
      firstName: auth0User.given_name!,
      lastName: auth0User.family_name!,
      username: auth0User.sub!,
      email: auth0User.email!,
      pictureUrl: Optional.empty(),
    });
  }

  async authenticatedFan(): Promise<FanIdentity> {
    const authenticatedFan = await this.optionalAuthenticatedFan();
    return authenticatedFan.orElseThrow(() => new Error('Should be authenticated'));
  }

  private extractUserNameFrom(user: User): string {
    if (user.name === undefined || user.name.replaceAll('undefined', '').trim() === '') {
      return user.nickname!;
    }

    return user.name!.split('@')[0];
  }

  async isAuthenticated(): Promise<boolean> {
    return this.client.isAuthenticated().catch(error => {
      this.logger.error('Failed to check if authenticated', error);

      return false;
    });
  }

  async jwtToken(): Promise<Optional<string>> {
    const authenticated = await this.isAuthenticated();
    if (!authenticated) {
      return Optional.empty();
    }

    let token: string;
    try {
      token = await this.client.getTokenSilently();
    } catch (error: any) {
      this.logger.error('Failed to get Token silently', error);
      return Optional.empty();
    }
    return Optional.of(token);
  }

  private throwError(error: any): never {
    this.alertBus.alert({ message: 'errors.authentication.unknown', type: 'danger' });
    throw new Error(error);
  }
}
