import { MailToContactVue } from '@/fairplayer/primary/email';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { createTosForm } from '@/fairplayer/primary/tos/Tos.ui';
import { computed, defineComponent, inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { LanguageSelectorVue } from '@/fairplayer/primary/page-footer/language-selector';
import { ThemeSelectorVue } from '@/fairplayer/primary/page-footer/theme-selector';

export default defineComponent({
  name: 'PageFooter',

  components: {
    MailToContactVue,
    LanguageSelectorVue,
    ThemeSelectorVue,
  },

  setup() {
    const staticFilesUri = import.meta.env.VITE_STATIC_FILES_URL;
    const { t } = useI18n();
    const route = useRoute();
    const clubRepository = inject(clubRepositoryKey)!;

    const isHomepage = computed(() => route.name === 'clubHomepage');
    const pageFooterCollapsed = ref(isHomepage.value);

    const currentClub = clubRepository.findCurrentClub().map(fromClub);
    const showLanguageSelector = currentClub.map(club => club.languages.length > 1).orElse(false);
    const documentationUrl = currentClub.map(club => `${staticFilesUri}/clubs/${club.slug}/Fairplayer-documentation.pdf`).orElse('');
    const documents = createTosForm().map(tos => tos.id);
    const isFoundation = currentClub.map(club => club.isFoundation).orElse(false);

    return {
      pageFooterCollapsed,
      isFoundation,
      documents,
      documentationUrl,
      staticFilesUri,
      showLanguageSelector,
      t,
    };
  },
});
