import { InjectionKey } from 'vue';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { ModalOpened } from '@/common/domain/modal/ModalOpened';
import { BusCallback } from '@/common/domain/BusCallback';

export const modalBusKey: InjectionKey<ModalBus> = Symbol();

export interface ModalBus {
  open(modal: ModalOpened): void;
  close(): void;
  opened(callback: BusCallback<ModalOpened>): Unsubscribe;
  closed(callback: BusCallback<void>): Unsubscribe;
  emit(event: string): void;
  onEmitted(event: string, callback: BusCallback<void>): Unsubscribe;
}
