import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/fairplayer/domain/club/ClubSlug';
import { Club } from '@/fairplayer/domain/club/Club';
import { BackendClub, toClub } from '@/fairplayer/secondary/club/BackendClub';
import { ClubSummary } from '@/fairplayer/domain/club/ClubSummary';
import { PaymentTypeAvailability } from '@/fairplayer/domain/club/PaymentTypeAvailability';
import { PaymentType } from '@/fairplayer/domain/fan/order/PaymentType';
import { BackendClubSummary, toClubSummary } from '@/fairplayer/secondary/club/BackendClubSummary';
import { BackendPaymentTypeAvailability, toPaymentTypeAvailability } from '@/fairplayer/secondary/club/BackendPaymentTypeAvailability';

export class BackendClubRepository {
  constructor(private backendCaller: BackendCaller) {}

  list(): Promise<ClubSummary[]> {
    return this.backendCaller.get<BackendClubSummary[]>('api/clubs').then(clubs => clubs.data.map(toClubSummary));
  }

  get(clubSlug: ClubSlug): Promise<Club> {
    return this.backendCaller.get<BackendClub>(`api/clubs/${clubSlug}`).then(club => toClub(club.data));
  }

  getPaymentTypeAvailability(clubSlug: ClubSlug, paymentType: PaymentType): Promise<PaymentTypeAvailability> {
    return this.backendCaller
      .get<BackendPaymentTypeAvailability>(`api/clubs/${clubSlug}/payment-types/${paymentType}`)
      .then(response => toPaymentTypeAvailability(response.data));
  }
}
