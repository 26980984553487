import { Club } from '@/fairplayer/domain/club/Club';
import { ClubState } from '@/fairplayer/domain/club/ClubState';
import { fromPartner, PartnerUi } from '@/fairplayer/primary/club/Partner.ui';
import { fromGiveaway, GiveawayUi } from '@/fairplayer/primary/club/Giveaway.ui';
import { ClubSummaryUi, fromClubSummary } from '@/fairplayer/primary/homepage/club-selection/ClubSummary.ui';
import { ClubWeb3Model } from '@/common/domain/club/ClubWeb3Model';
import { ClubModel } from '@/fairplayer/domain/club/ClubModel';
import { FiatUi, fromFiat } from '@/common/primary/token/FiatUi';

export interface ClubUi {
  name: string;
  slug: string;
  model: ClubModel;
  isOffChain: boolean;
  hasTokensPartner: boolean;
  isFoundation: boolean;
  isMarketplace: boolean;
  logoUrl: string;
  bannerUrl: string;
  contactEmail: string;
  state: ClubState;
  partners: PartnerUi[];
  activeGiveaways: GiveawayUi[];
  fiatAccepted: boolean;
  coinsEnabled: boolean;
  coinsBuyable: boolean;
  membershipFee?: FiatUi;
  languages: string[];
  linkedClubs: ClubSummaryUi[];
}

export const fromClub = (club: Club): ClubUi => ({
  name: club.name,
  slug: club.slug,
  model: club.model,
  isOffChain: club.web3Model === ClubWeb3Model.OFF_CHAIN,
  hasTokensPartner: club.web3Model === ClubWeb3Model.KRIPTOWN,
  isFoundation: club.model === ClubModel.FOUNDATION,
  isMarketplace: club.model === ClubModel.MARKETPLACE,
  logoUrl: club.logoUrl,
  bannerUrl: club.bannerUrl,
  contactEmail: club.contactEmail,
  state: club.state,
  coinsBuyable: club.coinsEnabled && club.web3Model === ClubWeb3Model.KRIPTOWN,
  partners: club.partners.map(fromPartner),
  activeGiveaways: club.activeGiveaways.map(fromGiveaway),
  coinsEnabled: club.coinsEnabled,
  fiatAccepted: club.fiatAccepted,
  membershipFee: club.membershipFee.map(fromFiat).orElseUndefined(),
  languages: club.languages,
  linkedClubs: club.linkedClubs.map(fromClubSummary),
});
