import { InjectionKey } from 'vue';
import { Optional } from '@/common/domain/Optional';

export const themeStorageKey: InjectionKey<ThemeStorage> = Symbol();

export interface ThemeStorage {
  get(): Optional<string>;
  save(theme: string): void;
  set(theme: string): void;
}
