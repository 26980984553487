export enum KycStatus {
  KYC_NOT_STARTED = 'KYC_NOT_STARTED',
  TOS_VALIDATED = 'TOS_VALIDATED',
  FAN_PERSONAL_INFO_FILLED = 'FAN_PERSONAL_INFO_FILLED',
  KYC_QUESTIONS_ANSWERED = 'KYC_QUESTIONS_ANSWERED',
  ID_DOCUMENT_UPLOADED = 'ID_DOCUMENT_UPLOADED',
  ID_DOCUMENT_REFUSED = 'ID_DOCUMENT_REFUSED',
  KYC_VALIDATED = 'KYC_VALIDATED',
  KYC_REFUSED = 'KYC_REFUSED',
}

export const KYC_COMPLETED_STATUSES = [KycStatus.ID_DOCUMENT_UPLOADED, KycStatus.KYC_VALIDATED, KycStatus.KYC_REFUSED];
