import { FaqCardVue } from '@/fairplayer/primary/home/club-homepage/faq/faq-card';
import { defineComponent, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';

export default defineComponent({
  name: 'KriptownFaq',

  components: {
    FaqCardVue,
  },

  setup() {
    const clubRepository = inject(clubRepositoryKey)!;
    const { t } = useI18n();

    const club = fromClub(clubRepository.getCurrentClub());
    const tokenDefinitionId = club.coinsBuyable ? 'kriptownBuyableTokenDefinition' : 'kriptownTokenDefinition';
    const showExclusiveInFiat = club.fiatAccepted;

    return {
      tokenDefinitionId,
      showExclusiveInFiat,
      t,
    };
  },
});
