import { Optional } from '@/common/domain/Optional';
import { BackendIdentityProof } from '@/fairplayer/secondary/fan/BackendFanKycInfo';
import { KriptownKycDocumentDetails } from '@/fairplayer/secondary/fan/KriptownKycDocumentDetails';
import { MangopayKycDocumentDetails, MangopayKycDocumentRefusedReason } from '@/fairplayer/secondary/fan/MangopayKycDocumentDetails';

export type KycDocumentStatus = 'TO_SEND' | 'IN_REVIEW' | 'VALIDATED' | 'REFUSED' | 'EXPIRED';

const getKriptownStatus = (kycDocumentDetails: BackendIdentityProof) =>
  Optional.ofUndefinable(kycDocumentDetails.kycDocument)
    .map(document => document.status)
    .orElseUndefined();

const getMangopayStatus = (kycDocumentDetails: BackendIdentityProof) =>
  Optional.ofUndefinable(kycDocumentDetails.kycDocumentMangoPay)
    .map(document => document.status)
    .orElseUndefined();

export const isExpired = (kycDocumentDetails: BackendIdentityProof | null): boolean => {
  if (kycDocumentDetails === null) {
    return false;
  }

  const kriptownStatus = getKriptownStatus(kycDocumentDetails);
  const mangopayStatus = getMangopayStatus(kycDocumentDetails);

  return kriptownStatus === 'EXPIRED' || mangopayStatus === 'OUT_OF_DATE';
};

export const computeDocumentStatus = (kycDocumentDetails: BackendIdentityProof | null): KycDocumentStatus => {
  if (kycDocumentDetails === null) {
    return 'TO_SEND';
  }

  const kriptownStatus = getKriptownStatus(kycDocumentDetails);
  const mangopayStatus = getMangopayStatus(kycDocumentDetails);

  if (kriptownStatus === 'REFUSED' || mangopayStatus === 'REFUSED') {
    return 'REFUSED';
  }
  if (kriptownStatus === 'EXPIRED' || mangopayStatus === 'OUT_OF_DATE') {
    return 'EXPIRED';
  }
  if (kriptownStatus === 'PENDING' || mangopayStatus === 'VALIDATION_ASKED') {
    return 'IN_REVIEW';
  }
  if (kriptownStatus === 'SUCCEEDED' || mangopayStatus === 'VALIDATED') {
    return 'VALIDATED';
  }

  return 'TO_SEND';
};

const computeDocumentMessage = (kycDocumentDetails: BackendIdentityProof | null): string | null | undefined => {
  if (kycDocumentDetails === null) {
    return null;
  }

  const kriptownMessage = Optional.ofUndefinable(kycDocumentDetails.kycDocument)
    .map(document => document.refusedComment)
    .orElseUndefined();
  const mangopayMessage = Optional.ofUndefinable(kycDocumentDetails.kycDocumentMangoPay)
    .map(document => document.refusedComment)
    .orElseUndefined();

  return kriptownMessage || mangopayMessage;
};

const computeDocumentErrorType = (kycDocumentDetails: BackendIdentityProof | null): string | null | undefined => {
  if (kycDocumentDetails === null) {
    return null;
  }

  return Optional.ofUndefinable(kycDocumentDetails.kycDocumentMangoPay)
    .map(document => document.refusedType)
    .orElseUndefined();
};

export const computeDocumentError = (kycDocumentDetails: BackendIdentityProof | null): KycDocumentError => ({
  type: computeDocumentErrorType(kycDocumentDetails),
  message: computeDocumentMessage(kycDocumentDetails),
});

export interface KycDocumentError {
  type?: MangopayKycDocumentRefusedReason | null;
  message?: string | null;
}

export interface KycDocumentDetails {
  kycDocument: KriptownKycDocumentDetails | null;
  kycDocumentMangopay: MangopayKycDocumentDetails | null;
}
