import { Program } from '@/fairplayer/domain/program/Program';
import { renderMarkdown } from '@/common/primary/MarkdownRenderer';

export interface ProgramUi {
  slug: string;
  imageUrl: string;
  name: string;
  description: string;
}

export const fromProgram = (program: Program): ProgramUi => ({
  slug: program.slug,
  imageUrl: program.imageUrl,
  name: program.name,
  description: renderMarkdown(program.description),
});
