import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { fanRepositoryKey } from '@/fairplayer/domain/fan/FanRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { PageVue } from '@/fairplayer/primary/page';
import { ToastsListVue } from '@/fairplayer/primary/toasts/toasts-list';
import { inject, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { FairplayerButtonVue } from '@/common/primary/button';
import { LogoutVue } from '@/common/primary/auth/logout';

export default {
  name: 'TwoFactorAuthentication',

  components: { ClubLogoVue, PageVue, ToastsListVue, FairplayerButtonVue, LogoutVue },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const fanRepository = inject(fanRepositoryKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;

    const twoFactorAuthenticationCode = ref('');
    const invalidTwoFactorAuthentication = ref(false);
    const isSendingTwoFactorAuthentication = ref(false);
    const isAskingNewTwoFactorAuthentication = ref(false);

    const sendTwoFactorAuthentication = async () => {
      isSendingTwoFactorAuthentication.value = true;
      const valid = await fanRepository.sendTwoFactorAuthentication(twoFactorAuthenticationCode.value);
      isSendingTwoFactorAuthentication.value = false;
      invalidTwoFactorAuthentication.value = !valid;

      if (valid) {
        await pageRedirecter.navigateTo(route.query.redirect!.toString());
      }
    };

    const newTwoFactorAuthentication = async () => {
      isAskingNewTwoFactorAuthentication.value = true;
      await fanRepository.generateNewTwoFactorAuthentication();
      isAskingNewTwoFactorAuthentication.value = false;
    };

    return {
      twoFactorAuthenticationCode,
      newTwoFactorAuthentication,
      sendTwoFactorAuthentication,
      invalidTwoFactorAuthentication,
      isSendingTwoFactorAuthentication,
      isAskingNewTwoFactorAuthentication,
      t,
    };
  },
};
