include ../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing.-s-lg
      .fp-vertical-spacing--line.-justify-center(data-test='club-logo')
        ClubLogoVue
      template(v-if='!fan.isLoading()')
        .fp-vertical-spacing--line.-justify-center
          .fp-horizontal-spacing.-items-center.-s-sm
            .fp-horizontal-spacing--column
              h1.fp-title(data-test='fan-identity') {{ fan.value().identity.name }}
            .fp-horizontal-spacing--column(v-if='kycValidated')
              span(data-test='kyc-validated-icon' :title='t("profile.verifiedUser")')
                +fp-icon('circle-wavy-check', {size: '24', color: 'success'})
        .fp-vertical-spacing--line
          KycSummaryVue(:fan='fan.value()')
        .fp-vertical-spacing--line(v-if='!orderList.isLoading()')
          OrderListVue(:order-list='orderList.value()')
        template(v-if='showRedirectToClubSelection')
          .fp-separator.-secondary
          .fp-vertical-spacing--line
            h3.fp-text.-color-shade-900 {{ t('profile.goToClubSelection.title') }}
            router-link.fp-link(:to='{name: \'clubsPage\'}' data-test='go-to-club-selection') {{ t('profile.goToClubSelection.button') }}
  template(v-slot:footer)
    LogoutVue
