import { KriptownCaller } from '@/common/secondary/KriptownCaller';
import { CardId } from '@/fairplayer/domain/fan/card/CardId';
import { RandomStringGenerator } from '@/common/domain/RandomStringGenerator';
import { CurrentBrowserParameters } from '@/common/secondary/CurrentBrowserParameters';
import { KriptownViewerCreditAccountByCardTransactionResult } from '@/fairplayer/secondary/fan/wallet/KriptownViewerCreditAccountByCardTransactionResult';
import { CardSecurityCode } from '@/fairplayer/domain/fan/card/CardSecurityCode';
import { CardTransactionRedirection } from '@/fairplayer/domain/fan/card/CardTransactionRedirection';
import { Tokens } from '@/common/domain/token/Tokens';
import { ClubRepository } from '@/fairplayer/domain/club/ClubRepository';
import { Authentication } from '@/common/domain/auth/Authentication';

const CREDIT_BY_CARD = `
mutation WalletCreditByCardCreditForm($input: CreditAccountByCardInput!) {
  creditAccountByCard(input: $input) {
    transaction {
      secureModeReturnURL
      secureModeRedirectURL
      applied3DSVersion
    }
  }
}`;

const toCardRedirection = (redirectUrl: string): CardTransactionRedirection => ({
  redirectUrl,
});

const floorToTwoDecimals = (value: number) => Math.floor(value * 100) / 100;

export class KriptownWalletRepository {
  constructor(
    private kriptownCaller: KriptownCaller,
    private authentication: Authentication,
    private clubRepository: ClubRepository,
    private randomStringGenerator: RandomStringGenerator,
    private currentBrowserParameters: CurrentBrowserParameters
  ) {}

  async creditWalletByCard(cardId: CardId, tokens: Tokens, securityCode: CardSecurityCode): Promise<CardTransactionRedirection> {
    const response = await this.kriptownCaller.mutate<KriptownViewerCreditAccountByCardTransactionResult>(CREDIT_BY_CARD, {
      cardId,
      amount: floorToTwoDecimals(tokens.totalCost.value),
      cvv: securityCode,
      transactionRef: this.randomStringGenerator.randomUuid(),
      browser: this.currentBrowserParameters.get(),
      redirection3DSV2Url: await this.computeBackendRedirectionUrl(tokens),
    });
    return this.buildCardRedirection(response.data?.creditAccountByCard?.transaction?.secureModeRedirectURL, tokens);
  }

  private buildCardRedirection(secureModeRedirectUrl: string | undefined, tokens: Tokens) {
    if (secureModeRedirectUrl) {
      return toCardRedirection(secureModeRedirectUrl);
    }

    return toCardRedirection(`${this.computeSuccessFrontendRedirectionUrl(tokens)}&success=1`);
  }

  private async computeBackendRedirectionUrl(tokens: Tokens) {
    const successFrontendRedirectionUrl = this.computeSuccessFrontendRedirectionUrl(tokens);
    const encodedSuccessFrontendRedirectionUrl = encodeURIComponent(successFrontendRedirectionUrl);
    const jwtToken = (await this.authentication.jwtToken()).orElseThrow(() => new Error('Should be authenticated'));
    const backendUrl = import.meta.env.VITE_KRIPTOWN_BACKEND_URL;
    return `${backendUrl}/confirmation3DS?token=${jwtToken}&redirectUrl=${encodedSuccessFrontendRedirectionUrl}`;
  }

  computeSuccessFrontendRedirectionUrl(tokens: Tokens) {
    const flatTokens = {
      fiatValue: tokens.totalCost.value.toString(),
      fiatCurrency: tokens.totalCost.currency,
      coinsAmount: tokens.coins.amount.toString(),
      coinsTicker: tokens.coins.ticker,
    };
    const urlParams = new URLSearchParams(flatTokens).toString();
    return `${window.location.origin}/clubs/${this.clubRepository.getCurrentSlug()}/payment-processing?${urlParams}`;
  }
}
