include ../../../../common/primary/icon/icon.mixin.pug

.fp-vertical-spacing--line(data-test='giveaway-participation-quiz.title')
  .fp-icon-with-text
    +fp-icon('edit', {color: 'shade-700', size: '18'})
    .fp-icon-with-text--text
      .fp-icon-with-text--text-line
        span.fp-text.-text-lg.-color-shade-900.-text-semi-bold {{ t('giveaway.answerQuestions', questions.length) }}
.fp-vertical-spacing--line(v-for='(question, questionIndex) in questions' :key='questionIndex')
  .fp-vertical-spacing.-s-xs
    .fp-vertical-spacing--line(v-if='question.preTitle')
      span.fp-text.-color-shade-900(v-html='question.preTitle' :data-test='`question-${questionIndex}.pre-title`')
    .fp-vertical-spacing.-s-sm(v-if='question.answers.length > 0')
      .fp-vertical-spacing--line(:data-test='`question-${questionIndex}`')
        span.fp-text.-color-shade-900.-text-semi-bold {{ question.title }}
          span.fp-text.-color-danger(v-if= 'question.required') &nbsp*
      .fp-vertical-spacing--line(v-for='(answer, answerIndex) in question.answers' :key='answerIndex' :data-test='`question-${questionIndex}-answer-${answerIndex}`')
        .fp-checkable-element
          .fp-checkable-element--checkbox
            input.fp-radio(:id='`question-${questionIndex}-answer-${answerIndex}`' type='radio' :name='`question-${questionIndex}`' :required='question.required' :disabled='formDisabled' @change='selectAnswered(question.id, answer.id)')
          .fp-checkable-element--label
            label(:for='`question-${questionIndex}-answer-${answerIndex}`')
              .fp-text.-color-shade-900 {{ answer.text }}
    template(v-else)
      .fp-vertical-spacing--line
        label.fp-field(:for='`open-field-question-${questionIndex}-answer`' :data-test='`open-field-question-${questionIndex}`')
          span.fp-text.-color-shade-900 {{ question.title }}
          span.fp-text.-color-danger(v-if= 'question.required') &nbsp*
      .fp-vertical-spacing--line
        input.fp-input-text(:placeholder='t(\'giveaway.yourAnswer\')' :id='`open-field-question-${questionIndex}-answer`' :name='`open-field-question-${questionIndex}-answer`' :data-test='`question-${questionIndex}-input`' :disabled='formDisabled' @change='event => inputAnswered(question.id, event.target.value)' :required='question.required')
