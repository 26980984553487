import { KriptownCaller } from '@/common/secondary/KriptownCaller';
import { KriptownGenerateNewTwoFactorAuthResult } from '@/fairplayer/secondary/fan/KriptownGenerateNewTwoFactorAuthResult';
import { VerifyTwoFactorAuthResult } from '@/fairplayer/secondary/fan/VerifyTwoFactorAuthResult';
import { toKriptownAddKycDocumentInput } from '@/fairplayer/secondary/fan/KriptownAddKycDocumentInput';
import { KriptownUserAddKycDocumentResult } from '@/fairplayer/secondary/fan/KriptownUserAddKycDocumentResult';
import { Optional } from '@/common/domain/Optional';
import { euro, Fiat } from '@/common/domain/token/Fiat';
import { Observable } from '@/common/domain/Observable';
import { KriptownViewerBalanceResult } from '@/fairplayer/secondary/fan/KriptownViewerBalanceResult';

const GET_VIEWER_BALANCE = `
query ViewerBalance {
  viewer {
      balance
    }
  }
`;

const UPLOAD_VIEWER_KYC_DOCUMENTS = `
mutation UserAddKycDocument($input: AddKycDocumentInput!) {
  addKycDocument(input: $input) {
    kyc {
      id
    }
  }
}
`;

const GENERATE_NEW_TWO_FACTOR_AUTHENTICATION = `
mutation GenerateNewTwoFactorAuth {
  generateNewTwoFactorAuth { success }
}
`;

const SEND_TWO_FACTOR_AUTHENTICATION = `
mutation VerifyTwoFactorAuth($input: VerifyTwoFactorAuthInput!) {
  verifyTwoFactorAuth(input: $input) { success }
}
`;

export class KriptownViewerRepository {
  constructor(private kriptownCaller: KriptownCaller) {}

  generateNewTwoFactorAuthentication(): Promise<boolean> {
    return this.kriptownCaller
      .mutate<KriptownGenerateNewTwoFactorAuthResult>(GENERATE_NEW_TWO_FACTOR_AUTHENTICATION)
      .then(result => result.data!.GenerateNewTwoFactorAuth);
  }

  sendTwoFactorAuthentication(code: string): Promise<boolean> {
    return this.kriptownCaller
      .mutate<VerifyTwoFactorAuthResult>(SEND_TWO_FACTOR_AUTHENTICATION, { token: code })
      .then(result => result.data!.verifyTwoFactorAuth)
      .catch(() => false);
  }

  uploadKycDocument(files: File[]): Promise<boolean> {
    const input = toKriptownAddKycDocumentInput(files);
    return this.kriptownCaller.mutate<KriptownUserAddKycDocumentResult>(UPLOAD_VIEWER_KYC_DOCUMENTS, input).then(result => !!result);
  }

  observableViewerBalance(): Observable<Optional<Fiat>> {
    return this.kriptownCaller
      .watchQuery<KriptownViewerBalanceResult>(GET_VIEWER_BALANCE, { pollInterval: 1000 })
      .map(({ data }) => Optional.ofZero(data.viewer.balance).map(euro));
  }
}
