import { FairplayerButtonVue } from '@/common/primary/button';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { ClubLogoVue } from '@/fairplayer/primary/club-logo';
import { fromClub } from '@/fairplayer/primary/club/Club.ui';
import { Loader } from '@/loader/primary/Loader';
import { computed, defineComponent, inject, onMounted, onUnmounted, PropType, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LoginVue } from '@/common/primary/auth/login';
import { authenticationKey } from '@/common/domain/auth/Authentication';
import { useRouter } from 'vue-router';
import { pageScrollBusKey } from '@/common/domain/page-scroll/PageScrollBus';
import { Unsubscribe } from '@/common/domain/Unsubscribe';

export default defineComponent({
  name: 'ClubHomepageHeader',

  components: {
    ClubLogoVue,
    FairplayerButtonVue,
    LoginVue,
  },

  props: {
    isAuthenticated: {
      type: Object as PropType<Loader<boolean>>,
      required: true,
    },
    isNotReferred: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    hasExclusives: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    hasPrograms: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();

    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const pageScrollBus = inject(pageScrollBusKey)!;

    let unsubscribePageScrollBus: Unsubscribe;
    const banner = ref();
    const bannerTranslateY = ref(0);

    const club = fromClub(clubRepository.getCurrentClub());

    const fullHeight = computed(
      () => club.isFoundation && !props.isAuthenticated.isLoading() && (!props.isAuthenticated.value() || props.isNotReferred)
    );

    const logout = async () => {
      await authentication.logout(true);
      await router.push({ name: 'homepage' });
    };

    const updateBannerTranslateY = (px: number) => {
      bannerTranslateY.value = px * 0.5;
    };

    onMounted(() => {
      unsubscribePageScrollBus = pageScrollBus.onScroll(updateBannerTranslateY);
    });

    onUnmounted(() => {
      unsubscribePageScrollBus();
    });

    return {
      banner,
      bannerTranslateY,
      club,
      fullHeight,
      logout,
      t,
    };
  },
});
