import { CreditWalletConfirmModalVue } from '@/fairplayer/primary/wallet/cards-list/credit-wallet-form/credit-wallet-confirm-modal';
import { Unsubscribe } from '@/common/domain/Unsubscribe';
import { modalBusKey } from '@/common/domain/modal/ModalBus';
import { Coins } from '@/common/domain/token/Coins';
import { Tokens } from '@/common/domain/token/Tokens';
import { FairplayerButtonVue } from '@/common/primary/button';
import { creditWalletConfirmModal } from '@/common/primary/modal/Modals';
import { pageRedirecterKey } from '@/common/primary/PageRedirecter';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { clubRepositoryKey } from '@/fairplayer/domain/club/ClubRepository';
import { PaymentTypeAvailability } from '@/fairplayer/domain/club/PaymentTypeAvailability';
import { PaymentType } from '@/fairplayer/domain/fan/order/PaymentType';
import { walletRepositoryKey } from '@/fairplayer/domain/fan/wallet/WalletRepository';
import { Wallet } from '@/fairplayer/domain/Wallet';
import { Loader } from '@/loader/primary/Loader';
import { PageVue } from '@/fairplayer/primary/page';
import { computed, inject, onMounted, onUnmounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const MIN_CREDIT_FIAT_VALUE = 10;
const MIN_WALLET_FIAT_VALUE = 40;
const FIAT_VALUE_BEFORE_WARNING = 100;

export default {
  name: 'CreditWalletForm',

  components: { CreditWalletConfirmModalVue, FairplayerButtonVue, PageVue },

  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const clubRepository = inject(clubRepositoryKey)!;
    const modalBus = inject(modalBusKey)!;
    const pageRedirecter = inject(pageRedirecterKey)!;
    const walletRepository = inject(walletRepositoryKey)!;

    const conversionToken = ref(Loader.loading<TokensUi>());
    const club = clubRepository.getCurrentClub();
    const wallet = ref<Wallet>();
    const securityCode = ref<string>('');
    const toCreditTokens = ref<Tokens>();
    const toCreditTokensUi = ref<TokensUi>();
    const isCreditingWallet = ref(false);
    const showMaxFiatWarning = ref(false);
    const showNotEnoughFiat = ref(false);
    const minFiatValue = ref<number>();
    const tokenPaymentAvailability = ref<PaymentTypeAvailability>();
    const cardId = computed(() => route.params.cardId.toString());
    const maxFiatValue = computed(() => tokenPaymentAvailability.value?.limit.value);
    const maxCoinsAmount = computed(() => tokenPaymentAvailability.value!.limit.value / club.currentPrice.value);
    let unsubscribeConfirmModal: Unsubscribe;

    const computeConversionToken = () => {
      const oneCoin = new Coins(1 / club.currentPrice.value, club.ticker);
      conversionToken.value.loaded(
        fromTokens(Tokens.of(oneCoin).withTokenCost(club.currentPrice), {
          fiatValueDigit: 0,
        })
      );
    };

    const loadWallet = async (): Promise<void> => {
      wallet.value = await walletRepository.getForClub(clubRepository.getCurrentSlug());
    };

    const loadTokenPaymentAvailability = async (): Promise<void> => {
      tokenPaymentAvailability.value = await clubRepository.getPaymentTypeAvailability(clubRepository.getCurrentSlug(), PaymentType.COINS);
    };

    const displayConversionMessage = (): void => {
      const remainingFiatValue = tokenPaymentAvailability.value!.limit.value;
      showNotEnoughFiat.value = remainingFiatValue < minFiatValue.value!;
      showMaxFiatWarning.value = remainingFiatValue >= minFiatValue.value! && remainingFiatValue < FIAT_VALUE_BEFORE_WARNING;
    };

    onMounted(async () => {
      await Promise.all([loadWallet(), loadTokenPaymentAvailability()]);

      computeConversionToken();
      minFiatValue.value = Math.max(MIN_WALLET_FIAT_VALUE - wallet.value!.balance.totalCost.value, MIN_CREDIT_FIAT_VALUE);
      refreshToCreditTokensWithFiatValue(minFiatValue.value);
      displayConversionMessage();
      unsubscribeConfirmModal = modalBus.onEmitted('confirm', confirm);
    });

    onUnmounted(() => {
      unsubscribeConfirmModal();
    });

    const updatedFiatValue = (event: Event) => {
      const fiatValue = +(<HTMLInputElement>event.target).value;

      refreshToCreditTokensWithFiatValue(fiatValue);
    };

    const updatedCoinsAmount = (event: Event) => {
      const coinsAmount = +(<HTMLInputElement>event.target).value;

      refreshToCreditTokensWithCoinsAmount(coinsAmount);
    };

    const refreshToCreditTokensWithFiatValue = (fiatValue: number) => {
      const coinsAmount = fiatValue / club.currentPrice.value;
      refreshToCreditTokensWithCoinsAmount(coinsAmount);
    };

    const refreshToCreditTokensWithCoinsAmount = (coinsAmount: number) => {
      toCreditTokens.value = Tokens.of(new Coins(coinsAmount, club.ticker)).withTokenCost(club.currentPrice);
      toCreditTokensUi.value = fromTokens(toCreditTokens.value);
    };

    const openConfirmModal = async () => {
      const modal = creditWalletConfirmModal();
      modalBus.open({ component: modal });
    };

    const confirm = async () => {
      isCreditingWallet.value = true;
      const cardRedirection = await walletRepository
        .creditWalletByCard(cardId.value, toCreditTokens.value!, securityCode.value)
        .finally(() => {
          isCreditingWallet.value = false;
        });
      await pageRedirecter.navigateTo(cardRedirection.redirectUrl);
    };

    return {
      conversionToken,
      updatedFiatValue,
      updatedCoinsAmount,
      minFiatValue,
      maxFiatValue,
      maxCoinsAmount,
      securityCode,
      showMaxFiatWarning,
      showNotEnoughFiat,
      isCreditingWallet,
      toCreditTokensUi,
      tokenPaymentAvailability,
      t,
      confirm,
      openConfirmModal,
    };
  },
};
