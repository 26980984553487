import { Authentication } from '@/common/domain/auth/Authentication';
import { AuthenticationInMemory } from '@/common/secondary/auth/AuthenticationInMemory';
import { AuthenticationAuth0, createAuth0ClientWith } from '@/common/secondary/auth/AuthenticationAuth0';
import { createAuth0Client } from '@auth0/auth0-spa-js';
import { FairplayerAuthentication } from '@/common/secondary/auth/FairplayerAuthentication';
import { PageRedirecter } from '@/common/primary/PageRedirecter';
import { AlertBus } from '@/common/domain/alert/AlertBus';
import ConsoleLogger from '@/common/secondary/ConsoleLogger';
import { LoginContextRepository } from '@/common/domain/login/LoginContextRepository';
import { AuthenticationCognito, configureCognitoWith } from '@/common/secondary/auth/AuthenticationCognito';
import { Amplify } from 'aws-amplify';
import { CognitoClient } from '@/common/secondary/auth/CognitoClient';

export const createFairplayerAuthentication = async (
  pageRedirecter: PageRedirecter,
  loginContextRepository: LoginContextRepository,
  alertBus: AlertBus,
  consoleLogger: ConsoleLogger
): Promise<Authentication> => {
  if (import.meta.env.VITE_ADAPTERS_IN_MEMORY === 'true') {
    return new AuthenticationInMemory(loginContextRepository, pageRedirecter);
  }

  const kriptownAuth0Client = await createAuth0ClientWith(createAuth0Client, alertBus);
  configureCognitoWith(Amplify.configure, alertBus);
  const kriptownAuth0Provider = new AuthenticationAuth0(kriptownAuth0Client, loginContextRepository, alertBus, consoleLogger);
  const fairplayerAuthOProvider = new AuthenticationCognito(new CognitoClient(), alertBus, consoleLogger);
  return new FairplayerAuthentication(kriptownAuth0Provider, fairplayerAuthOProvider);
};
