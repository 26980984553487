include ../../../../common/primary/icon/icon.mixin.pug

PageVue
  template(v-slot:body)
    .fp-vertical-spacing(data-test='payment-failed')
      .fp-vertical-spacing--line.-align-center
        +fp-icon('close-circle', {color: 'danger', size: '50'})
      .fp-vertical-spacing--line.-align-center
        h2.fp-title {{ t('wallet.paymentFailed.fail') }}
      .fp-vertical-spacing--line.-align-center(data-test='failure-reason')
        .fp-text {{ t(`wallet.paymentFailed.failures.${failureReason}`) }}
      .fp-vertical-spacing--line.-justify-center
        FairplayerButtonVue(:text='t(\'wallet.paymentFailed.retry\')' icon='coin' data-test='retry-payment-button' @click='buyTokens')
