import { BrowserParameters } from '@/common/secondary/BrowserParameters';

export class CurrentBrowserParameters {
  get(): BrowserParameters {
    return {
      browserJavaEnabled: false,
      browserLanguage: 'fr-FR',
      browserColorDepth: 24,
      browserScreenHeight: 1080,
      browserScreenWidth: 1920,
      browserTZ: -60,
      challengeWindowSize: '05',
      browserJavascriptEnabled: true,
    };
  }
}
